import { Button, Card, Divider, Form, Icon, Input, Modal, Radio } from 'antd'
import React, { useEffect, useState, useCallback } from 'react'
import className from 'classnames'
import ImageSelect from '../../../../../../../../../components/Form/ImageSelect'
import { PopupMedia } from '../../../../../../../../Library/internal'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import UploadVideo from '../../../../../../../../../components/UploadVideo/UploadVideo'
import MediaPreview from './MediaPreview'
import uuidv4 from 'uuid/v4'
import {
  CreateAudio,
  AddDetailAudio
} from '../../../../../PageBuilder/Components/Audio/internal/Create'
import { AudioModalTitleIcon } from '../Icons'
import ImageUpload from '../../../../../../../../../components/Core/ImageUpload/ImageUpload'

const optionObject = {
  index: uuidv4(),
  text: '',
  rating: 1,
  isCorrect: false
}

const mediaTypes = {
  image: 'image',
  video: 'video',
  audio: 'audio'
}

const defaultMedia = {
  type: null,
  id: null,
  link: ''
}

const defaultAudio = {
  id: null,
  link: ''
}

const buttonTypes = {
  save: 'save',
  insert: 'insert'
}

function ScenarioCreationForm(props) {
  const {
    form,
    form: { getFieldDecorator, setFieldsValue },
    isSaving,
    isFetching,
    onSubmit,
    scenarioData
  } = props

  const { t } = useTranslation()

  const [formData, setFormData] = useState({})

  const [scenarioStory, setScenarioStory] = useState('')

  const [options, setOptions] = useState([])
  const [selectedMedia, setSelectedMedia] = useState(defaultMedia)
  const [selectedButtonType, setSelectedButtonType] = useState(buttonTypes.save)
  const [showAudioGenerationModal, setShowAudioGenerationModal] = useState(
    false
  )
  const [audioSrc, setAudioSrc] = useState('')
  const [showAudioDetailModal, setShowAudioDetailModal] = useState(false)
  const [selectedAudio, setSelectedAudio] = useState(defaultAudio)
  const [optionsErr, setOptionsErr] = useState(false)

  useEffect(() => {
    if (isSaving) return
    setSelectedButtonType(buttonTypes.save)
  }, [isSaving])

  useEffect(() => {
    if (scenarioData) {
      const answers = scenarioData.answers

      if (scenarioData.media_id && scenarioData.media) {
        setSelectedMedia({
          type:
            scenarioData.media.media_format === 'IMAGE'
              ? mediaTypes.image
              : mediaTypes.video,
          id: scenarioData.media.id,
          link: scenarioData.media.link
        })
      }

      if (answers && answers.length > 0) {
        const options = answers.map((item, index) => ({
          index: item.index || index,
          text: item.answer,
          rating: item.rating,
          isCorrect: item.correct
        }))

        setOptions(options)
      }

      if (scenarioData.story_media_id && scenarioData.story_media) {
        setSelectedAudio({
          id: scenarioData.story_media.id,
          link: scenarioData.story_media.link
        })
      }

      if (scenarioData.story) {
        setScenarioStory(scenarioData.story)
      }
    }

    if (
      !scenarioData ||
      !scenarioData.answers ||
      (scenarioData.answers && scenarioData.answers.length === 0)
    ) {
      setOptions([optionObject])
    }
  }, [scenarioData])

  useEffect(() => {
    if (!showAudioDetailModal && audioSrc) {
      setAudioSrc('')
    }
  }, [showAudioDetailModal])

  useEffect(() => {
    handleSubmitDebouncing()
  }, [options, selectedAudio, selectedMedia, formData])

  const handleSubmitDebouncing = type => {
    if (isFetching) return

    if (isSaving) {
      _.debounce(t => handleSubmit(t), 1000)
    } else {
      handleSubmit(type)
    }
  }

  const addOption = () =>
    setOptions([...options, { ...optionObject, index: uuidv4() }])

  const handleCheck = (e, index) => {
    const isChecked = e.target.checked
    setOptions(prev =>
      prev.map((item, i) => ({
        ...item,
        isCorrect: i === index ? isChecked : false
      }))
    )
  }

  const removeOption = index => {
    setOptions(prev => {
      const newOptions = [...prev]
      newOptions.splice(index, 1)
      return newOptions
    })
  }

  const handleSubmit = useCallback(
    type => {
      form.validateFieldsAndScroll((err, values) => {
        if ((err || optionsErr) && type === buttonTypes.insert) {
          setSelectedButtonType(buttonTypes.save)
          return
        }

        const answers = options.map(({ text, isCorrect, rating, index }) => ({
          uid: index,
          answer: text,
          correct: isCorrect,
          rating
        }))
        onSubmit(
          {
            ...values,
            answers,
            audioId: selectedAudio.id
          },
          type
        )
      })
    },
    [form, options, onSubmit, selectedAudio]
  )

  const handleMediaSelection = media => {
    const { type, id, link } = media
    setSelectedMedia({ type, id, link })
    setFieldsValue({ scenarioMediaId: id })
  }

  const onCoverChange = cover =>
    handleMediaSelection({ ...cover, type: mediaTypes.image })

  const onSelectVideo = video =>
    handleMediaSelection({ ...video, type: mediaTypes.video })

  const handleClearMedia = () => {
    setSelectedMedia(defaultMedia)
    setFieldsValue({ scenarioMediaId: null })
  }

  const onSaveForLater = () => {
    setSelectedButtonType(buttonTypes.save)
    handleSubmitDebouncing(buttonTypes.save)
  }

  const onInsertScenario = () => {
    setSelectedButtonType(buttonTypes.insert)
    handleSubmitDebouncing(buttonTypes.insert)
  }

  const toggleAudioGenerationModal = () =>
    setShowAudioGenerationModal(pre => !pre)

  const toggleAudioDetailModal = () => setShowAudioDetailModal(pre => !pre)

  const onShowDetailModal = src => {
    setAudioSrc(src)
    toggleAudioDetailModal()
  }

  const getSelectedAudioFile = useCallback(
    files => {
      const file = files[0]
      if (!file) {
        return
      }

      const { id, link } = file
      setSelectedAudio({ id, link })
      toggleAudioDetailModal()
      setShowAudioGenerationModal(false)
    },
    [toggleAudioDetailModal, setShowAudioGenerationModal]
  )

  useEffect(() => {
    if (options.length > 0) {
      const hasCorrectAnswer = options.some(option => option.isCorrect)
      setOptionsErr(!hasCorrectAnswer)
    }
  }, [optionsErr])

  const validateAttempts = (rule, value, callback) => {
    if (!value) {
      callback('Attempts is required.')
    } else if (value < 2 || value > 4) {
      callback('Attempts must be between 2 and 4.')
    } else {
      callback()
    }
  }

  const audioModalTitle = <AudioModalTitleIcon t={t} isShowCreateAudioModal />

  const canAddOption = options.length <= 4

  const cantDeleteOption = options.length < 2

  return (
    <Form className="scenario-creation__form">
      <Form.Item label="Scenario Name" colon={false} required>
        {getFieldDecorator('scenarioName', {
          initialValue: scenarioData && scenarioData.name,
          rules: [
            {
              required: true,
              message: 'Scenario name is required'
            }
          ]
        })(
          <Input
            autoSize
            onChange={e => setFormData({ ...formData, name: e.target.value })}
          />
        )}
      </Form.Item>

      <Form.Item colon={false} required>
        <div className="scenario-creation__form__label">
          <span>Scenario Story</span>
          <span onClick={toggleAudioGenerationModal}>
            {' '}
            <Icon type="sound" /> Attach Audio
          </span>
        </div>

        {getFieldDecorator('scenarioStory', {
          initialValue: scenarioData && scenarioData.story,
          rules: [
            {
              required: true,
              message: 'Scenario story is required'
            }
          ]
        })(
          <Input.TextArea
            autoSize
            onChange={e => setFormData({ ...formData, story: e.target.value })}
          />
        )}
      </Form.Item>

      {selectedAudio.id && (
        <MediaPreview
          types={mediaTypes}
          isAudio
          media={selectedAudio}
          onRemove={handleClearMedia}
        />
      )}

      <div className="options-container">
        {canAddOption && (
          <span className="add-option" onClick={addOption}>
            + Add Option
          </span>
        )}

        {options.map((option, index) => (
          <div className="option-item" key={option.index}>
            <span className="option-index">{index + 1}.</span>

            <Form.Item colon={false} required>
              <Radio
                checked={option.isCorrect}
                onChange={e => handleCheck(e, index)}
              />
            </Form.Item>

            <Form.Item colon={false} className="option-text">
              {getFieldDecorator(`option${index + 1}`, {
                initialValue: option.text,
                rules: [
                  {
                    required: true
                  }
                ]
              })(
                <Input.TextArea
                  placeholder={`Option ${index + 1}`}
                  type="text"
                  style={{ width: '100%' }}
                  autoSize
                  onChange={e =>
                    setOptions(
                      options.map(o =>
                        o.index === option.index
                          ? { ...o, text: e.target.value }
                          : o
                      )
                    )
                  }
                />
              )}
            </Form.Item>

            <Form.Item label={`Rating`} colon={false}>
              {getFieldDecorator(`rating${index + 1}`, {
                initialValue: option.rating,
                rules: [
                  {
                    required: true
                  }
                ]
              })(
                <Input
                  style={{ width: '8ch' }}
                  min={1}
                  max={5}
                  type="number"
                  autoSize
                  onChange={e =>
                    setOptions(
                      options.map(o =>
                        o.index === option.index
                          ? { ...o, rating: e.target.value }
                          : o
                      )
                    )
                  }
                />
              )}
            </Form.Item>

            {cantDeleteOption && (
              <Icon
                type="delete"
                style={{ cursor: 'pointer', color: 'red' }}
                onClick={() => removeOption(index)}
              />
            )}
          </div>
        ))}

        {optionsErr && (
          <p style={{ color: 'red' }}>A correct answer is required.</p>
        )}
      </div>

      <Divider />

      <Form.Item label="Feedback" colon={false} required>
        {getFieldDecorator('feedback', {
          initialValue: scenarioData && scenarioData.feedback,
          rules: [
            {
              required: true,
              message: 'Feedback is required'
            }
          ]
        })(
          <Input.TextArea
            rows={2}
            autoSize
            onChange={e =>
              setFormData({ ...formData, feedback: e.target.value })
            }
          />
        )}
      </Form.Item>

      <Form.Item label="Attemps Count" colon={false} required>
        {getFieldDecorator('attemptsCount', {
          initialValue: scenarioData && scenarioData.attempts_count,
          rules: [
            {
              validator: validateAttempts
            }
          ]
        })(
          <Input
            type="number"
            min={1}
            max={3}
            step={1}
            autoSize
            onChange={e =>
              setFormData({ ...formData, attemptsCount: e.target.value })
            }
          />
        )}
      </Form.Item>
      <Form.Item label="Scenario Media" colon={false} required>
        {getFieldDecorator('scenarioMediaId', {
          initialValue: scenarioData && scenarioData.media_id,
          rules: [
            {
              required: true,
              message: 'Scenario media is required'
            }
          ]
        })(
          <>
            {selectedMedia.id ? (
              <MediaPreview
                media={selectedMedia}
                types={mediaTypes}
                onClearMedia={handleClearMedia}
              />
            ) : (
              <Card className="scenario-creation__form__image">
                <Form.Item colon={false} required={false}>
                  <ImageSelect
                    label="Image"
                    form={form}
                    required={false}
                    coverInput={null}
                    onCoverChange={onCoverChange}
                  />
                </Form.Item>

                <h1>OR</h1>

                <Form.Item label="Video" colon={false} required={false}>
                  <VideoSelect onSelectVideo={onSelectVideo} />
                </Form.Item>
              </Card>
            )}
          </>
        )}
      </Form.Item>

      <Divider />

      <div className="scenario-creation__form__button">
        <Button
          size="large"
          type="default"
          shape="round"
          onClick={onSaveForLater}
          loading={isSaving && selectedButtonType === buttonTypes.save}
        >
          Save For Later
        </Button>
        <Button
          size="large"
          className="shl-primary-btn"
          shape="round"
          onClick={onInsertScenario}
          loading={isSaving && selectedButtonType === buttonTypes.insert}
        >
          Insert Scenario
        </Button>
      </div>

      {showAudioGenerationModal && (
        <Modal
          visible={showAudioGenerationModal}
          className="custom-ant-modal"
          title={audioModalTitle}
          onCancel={() => setShowAudioGenerationModal(false)}
          footer={false}
          width={'88%'}
        >
          <CreateAudio
            t={t}
            text={scenarioStory}
            onShowDetailModal={onShowDetailModal}
          />
        </Modal>
      )}

      <Modal
        className="custom-ant-modal insert-audio-detail"
        visible={showAudioDetailModal}
        onCancel={toggleAudioDetailModal}
        width={'40%'}
        footer={false}
      >
        <AddDetailAudio
          src={audioSrc}
          isFromCreateAudio={false}
          getSelectedFile={getSelectedAudioFile}
          onHideDetailModal={toggleAudioDetailModal}
          t={t}
        />
      </Modal>
    </Form>
  )
}

const VideoSelect = ({ onSelectVideo }) => {
  const [showUploadModal, setShowUploadModal] = useState(false)
  const [showVideoLibray, setShowVideoLibrary] = useState(false)

  const { t } = useTranslation()

  const handleInsertData = (privateMedia, publicMedia, orgMedia) => {
    let tempData = []
    if (privateMedia) {
      const { rows: privateData } = privateMedia
      privateData.forEach(data => {
        tempData.push(data)
      })
    }

    if (publicMedia) {
      const { rows: publicData } = publicMedia
      publicData.forEach(publicData => {
        tempData.push(publicData)
      })
    }

    if (orgMedia) {
      const { rows: orgData } = orgMedia
      orgData.forEach(orgData => {
        tempData.push(orgData)
      })
    }

    const item = _.head(tempData.filter(value => value.isSelected))
    onSelectVideo(item)
    setShowVideoLibrary(false)
  }

  const handleUploadedVideos = videos => {
    const videoItem = videos && videos.length > 0 && videos[0]
    if (videoItem) {
      onSelectVideo(videoItem)
    }

    setShowUploadModal(false)
  }

  return (
    <div className={className('upload-dropzone', 'upload-dropzone_event')}>
      <div className="wrap_upload_buttons">
        <Button
          className="rounded"
          size="large"
          icon="picture"
          style={{ margin: '5px auto' }}
          onClick={e => {
            e.stopPropagation()
            setShowUploadModal(true)
          }}
        >
          {t('buttons:upload_video')}
        </Button>
        <Button
          style={{ margin: '5px auto' }}
          className="rounded"
          size="large"
          icon="picture"
          onClick={e => {
            e.stopPropagation()
            setShowVideoLibrary(true)
          }}
        >
          {t('buttons:select_media_library')}
        </Button>
      </div>

      <Modal
        visible={showUploadModal}
        onCancel={() => setShowUploadModal(false)}
        className="custom-ant-modal channel-video-modal"
        destroyOnClose
        width={800}
        header={null}
        footer={null}
      >
        <UploadVideo onUploadedVideos={handleUploadedVideos} />
      </Modal>

      <Modal
        visible={showVideoLibray}
        onCancel={() => setShowVideoLibrary(false)}
        width={'80%'}
        footer={null}
      >
        <div className="wrap_modal_tabs">
          <PopupMedia
            isPageBuilder
            types="videos"
            handleInsertData={handleInsertData}
          />
        </div>
      </Modal>
    </div>
  )
}

export default Form.create()(ScenarioCreationForm)
