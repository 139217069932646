/*
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-08-09 16:41:54
 * @LastEditTime: 2019-12-19 10:53:49
 * @LastEditTime: 2019-09-09 11:43:43
 * @LastEditors: Please set LastEditors
 */

export default {
  v4: {
    audio_modal: {
      title_upload: "Hochladen",
      title_update: "Aktualisieren",
      title_audio: "Audiodatei",
      btn_upload: "Hochladen",
      btn_update: "Aktualisieren",
      btn_close: "Schließen",
      btn_cancel: "Abbrechen",
      lbl_cover: "Titelbild",
      rule_cover: "Bitte laden Sie ein Audio-Cover hoch!",
      lbl_title: "Audio-Titel",
      rule_title: "Bitte geben Sie den Audio-Titel ein!",
      lbl_description: "Audio-Beschreibung",
      rule_description: "Bitte geben Sie eine Audio-Beschreibung ein!",
      lbl_file: "Audiodatei hochladen",
      rule_file: "Bitte laden Sie eine Audiodatei hoch!",
      rule_file_type: 'Bitte laden Sie eine gültige Audiodatei hoch!',
      lbl_select_file: "Audiodatei auswählen",
      msg_valid_file: 'Bitte wählen Sie eine gültige Audiodatei aus!',
      msg_success: 'Audiodatei erfolgreich hochgeladen',
      msg_failed: 'Medien-Daten konnten nicht zugewiesen werden!',
      msg_error: 'Fehler beim Hochladen der Datei!',
    },
    info_episode: "Ein Titelbild für die Episode",
    btn_delete_episode: "Diese Podcast-Episode löschen.",
    btn_edit_marketplace: "Marktplatzdetails bearbeiten",
    btn_save_marketplace: "Änderungen speichern",
    btn_ai_podcast: "Erstelle eine KI-gestützte Podcast-Episode.",
    btn_upload_podcast: "Lade deine eigene Podcast-Audio-Episode hoch.",
    attach_file: "Dateien hochladen, um mehr Inhalte mit Ihrem Publikum zu teilen",
    fileDownload: 'Download this file.',
    singleEpishode: 'Folge',
    podcast_music: 'Podcast und Musik',
    publishHeader: 'Podcast und Musik veröffentlichen',
    editHeader: 'Podcast und Musik bearbeiten',
    createHeader: 'Podcast und Musik erstellen',
    marketplaceHeader: 'Marktplatzdetails für Podcast und Musik',
    message_marketplace: 'Bitte füllen Sie das Marktplatzformular aus!',
    create_podcast_live: 'Live-Podcast und Musik',
    create_podcast_custom: 'Benutzerdefinierter Podcast und Musik',
    ai_Assisted_podcast_reation: 'KI-unterstützte Podcast- und Musikproduktion',
    publishTitle: 'Veröffentlichen Sie Podcast und Musik zur öffentlichen Ansicht.',
    customButton: 'Benutzerdefiniert erstellen',
    customTitle: "Speichert die am Podcast vorgenommenen Änderungen.",
    updateButton: 'Aktualisieren',
    createButton: 'Erstellen',
    createNewButton: 'Neu erstellen',
    updateTitle: 'Aktualisieren Sie die aktuellen Podcast- und Musikdetails.',
    createTitle: 'Erstellen Sie einen neuen Podcast und Musik',
    createNewTitle:
      'Neuer Podcast und Musik werden auf dem aktuellen Podcast und Musik mit neuen Eingabedetails erstellt.',
    podcastTitle: 'Episodenliste',
    podcastToolTipTitle: "Liste der Podcast-Episode(n)",
    uploadAudio: 'Hochladen',
    aiAudio: 'KI-Audio',
    emptyPodcast: 'Noch keine Podcast- und Musik-Audioaufnahmen vorhanden.',
    emptyCreatePodcast: "Bitte erstelle einen Podcast, um Musik hinzuzufügen.",
    playPodcast: 'Den ausgewählten Podcast und Musik abspielen.',
    pausePodcast: 'Den ausgewählten Podcast und Musik pausieren.',
    selectPodcas: 'Diesen Podcast und Musik auswählen.',
    downloadPodcast: 'Dieses Audio herunterladen.',
    uploadAudioFile:
      'Laden Sie neues Audio hoch und ersetzen Sie das aktuelle Audio.',
    deleteConfirm: 'Sind Sie sicher, dass Sie dieses Audio löschen möchten?',
    roomName: 'Podcast-Name',
    addGuestTitle: 'GASTREDNER HINZUFÜGEN',
    showTime: 'Show-Zeit',
    startTime: 'Startzeit',
    endTime: 'Endzeit',
    erorMsgImg: 'Bitte wählen Sie ein gültiges Bild aus.',
    failedMsgImg:
      'Das Bild konnte nicht geladen werden. Bitte versuchen Sie es erneut!',
    failedMsgUpload: 'Das Hochladen des Titelbildes ist fehlgeschlagen!',
    coverTitle: 'Titelbild',
    btnCoverUpload: 'Titelbild hochladen',

    is_private: 'Privat',
    to_attach: 'Datei anhängen',
    file_attached: 'Datei angehängt',
    main_event: 'Hauptveranstaltung',
    event: 'Veranstaltungen',
    upload_pdf: 'PDF hochladen',
    portrait: 'Hochformat',
    landscape: 'Querformat',
    import_from_library: 'Text aus der Medienbibliothek importieren',
    question_answer_ai: 'Frage/Antwort KI',
    take_maze: 'MetaMaze übernehmen',
    training: 'Ausbildung',
    select_transcribe_locale: 'Sprache zum Transkribieren auswählen',
    transcribe_limit: 'Bitte Transkriptionsminuten eingeben',
    not_complete: 'Marktplatzinformationen sind nicht vollständig',
    complete: 'Marktplatzinformationen sind vollständig',
    not_admin:
      'Administratorrechte erforderlich, um auf den Marktplatz zu veröffentlichen',
    no_course:
      'Bitte Titel eingeben und ein Bild auswählen, um den Marktplatz zu aktivieren',
    scorm_not_complete:
      'Bitte Titel eingeben und ein Bild auswählen, um die SCORM-Datei hochzuladen',
    not_found_maze: 'MetaMaze nicht gefunden',
    email_require: 'E-Mails sind erforderlich',
    poll_required: 'Umfragedaten sind erforderlich',
    alert: 'Achtung!',
    warning: 'Warnung!',
    ok: 'Ok',
    cancel: 'Abbrechen',
    done: 'Fertig',
    insert: 'Einfügen',
    private: 'Privat',
    public: 'Öffentlich',
    no_connection: 'Internetverbindung verloren',
    switch: 'Wechseln',
    share_screen: 'Bildschirm teilen',
    stop_share_screen: 'Bildschirmfreigabe beenden',
    share_screen_with_camera: 'Bildschirm mit Kamera teilen',
    stop_share_screen_with_camera: 'Bildschirmfreigabe mit Kamera beenden',
    camera_on: 'Kamera an',
    camera_off: 'Kamera aus',
    mic_on: 'Stummschaltung aufheben',
    mic_off: 'Stummschalten',
    fullscreen: 'Vollbild',
    live_now: 'Jetzt live',
    schedule_on: 'Geplant für',
    organisations: 'Organisationen',
    org_groups: 'Organisationsgruppen',
    specific_user: 'Spezifischer Benutzer',
    gif: 'GIF',
    unavailable: 'Nicht verfügbar',
    yes: 'Ja',
    no: 'Nein',
    event_duration_message: 'Maximal 300 Minuten (5 Stunden)',
    send_email: 'E-Mail-Benachrichtigung senden',
    admin_only: 'Nur für Administratoren',
    join: 'Beitreten',
    leave: 'Verlassen',
    share: 'Teilen',
    share_text:
      'Bitte geben Sie die E-Mail-Adresse der Person ein, mit der Sie diese Datei teilen möchten.',
    share_text_title: 'PDF teilen',
    share_text_title_txt: 'Text teilen',
    select_users: 'Benutzer auswählen',
    delete: 'Löschen',
    attachment_event: 'Anhänge für die Veranstaltung:',
    transcribe_limit: 'Transkriptionsminuten',
    monthly_transcribe: 'Monatliches Transkriptionslimit:',
    enable_ai: 'KI aktivieren:',
    enable_maze: 'MetaMaze aktivieren:',
    media_service: 'Mediendienste',
    allow_others: 'Anderen das Bearbeiten erlauben',
    join_breakout_room: 'Breakout-Raum beitreten',
    bio: 'Bio',
    linkedin: 'LinkedIn',
    select_org_report:
      'Bitte suchen und wählen Sie eine Organisation aus, um einen Bericht zu erstellen.',
    org_image_not_found: 'Bild nicht gefunden',
    insert_text: 'Text einfügen',
    insert_question_answer: 'Frage/Antwort KI',
    modal_title2: 'Textgalerie',
    my_text_library: 'Meine Textbibliothek',
    ai: 'KI',
    public_text_library: 'Öffentliche Textbibliothek',
    organization_text_library: 'Organisations-Textbibliothek',
    no_transcribed_text: 'Kein transkribierter Text gefunden',
    public_text_not_found: 'Öffentlicher transkribierter Text nicht gefunden',
    org_text_not_found: 'Organisations-Transkription nicht gefunden',
    org_video_not_found: 'Video nicht gefunden',
    canva: 'Von Canva erledigen',
    canva_open: 'Klicken Sie hier, um den Canva-Editor zu öffnen.',
    pdf: 'PDF',
    txt: 'TEXT',
    human: 'Premium',
    shared_file: 'Ihre Datei wurde erfolgreich geteilt.',
    error_shared_file:
      'Es gibt ein Problem beim Teilen der Datei. Bitte versuchen Sie es erneut.',
    publish: 'Veröffentlichen',
    maze_scenes: 'MetaMaze-Szenen',
    pexel_images: 'Pexel-Bilder',
    group: 'Gruppe',
    group_name: 'Gruppenname',
    select_group: 'Gruppe auswählen',

    search_maze: 'MetaMaze suchen',
    hosts: 'Gastgeber',
    avg_score: 'Durchschnittliche Punktzahl',
    maze_report: 'MetaMaze-Bericht',
    maze_view: 'MetaMaze-Ansicht',
    title_maze: 'MetaMaze',
    author: 'Autor',
    match: 'Pfadübereinstimmung',
    preview_mazeTitle: 'Titel: ',
    other: 'Andere',
    total: 'GESAMT',
    report_courses: 'KURSE',
    report_events: 'VERANSTALTUNGEN',
    report_maze: 'MAZE',
    report_personalize_learning: 'PERSONALISIERTES LERNEN',
    report_live_assessments: 'LIVE-ASSESSMENTS',
    report_skill_journey: 'SSkill-Reise',
    skill_journey: 'Skill-Reise',
    skill_journeys: 'Kompetenzreisen',
    date: 'DATUM: ',
    search_people: 'Personen suchen',
    passed: 'BESTANDEN',
    failed: 'NICHT BESTANDEN',
    item: 'ELEMENT',
    type: 'TYP',
    minimum_pass_score: 'MINDESTPUNKTE FÜR BESTANDEN',
    resources_count: 'ANZAHL DER RESSOURCEN',
    back: 'ZURÜCK',
    resources: 'RESSOURCEN',
    time_spent: 'ZEITAUFWAND (MINUTEN)',
    detail: 'DETAIL',
    watched_time_mins: 'ANGESEHENE ZEIT (MINUTEN)',
    personalize_learning: 'Personalisieren Lernen',
    result: 'Ergebnis',
    assessment_title: 'Titel',

    marketPlace_graph: 'Marktplatz-Diagramm',
    attachments: 'Zusätzliche Anhänge',

    new_scorm_course: 'Neuen SCORM-Kurs hochladen',
    upload_scorm_course: 'SCORM-Kurs hochladen',
    new_maze: 'Neuen MetaMaze erstellen',
    new_skill_journey: 'Eine Skill Journey erstellen',
    tags_scorm: 'SCORM',
    tags_skillJourney: 'Skill Journey',
    new_discussion_room: 'Neuen Podcast erstellen',

    title: 'Was möchten Sie heute tun?',
    build_course: 'Meinen Kurs erstellen',
    live_broadcast: 'Live-Übertragung',
    invite_users: 'Benutzer in meine Organisation einladen',
    build_voice_over: 'Mein Voiceover erstellen',
    view_reports: 'Berichte anzeigen',
    upload_media: 'Meine Medien oder Arbeiten hochladen und teilen',
    main_menu: 'Hauptmenü',
    get_started: 'LOSLEGEN',
    search_group: 'Organisationsgruppe suchen',
    ai_course: 'KI-Kurs',
    search_user: 'Unternehmensname suchen',

    no_maze: 'Es gibt derzeit keine MetaMaze.',
    conference_event_title: 'Konferenzveranstaltungen',
    conference_event_subTitle: 'Abgeschlossene Konferenzveranstaltungen',
    conference_event: 'Es gibt derzeit keine Konferenzveranstaltungen.',
    no_skill: 'Es gibt derzeit keine Skill Journeys.',
    no_podcast: 'Es gibt derzeit keine Podcasts.',
    maze_channel: 'MetaMaze',
    past_live_assessment: 'Vergangene Live-Assessments',
    no_live_assessment: 'Es gibt derzeit keine Live-Assessments.',
    enrolled_courses: 'Kurse',
    my_video_channel: 'Meine Videokanäle',
    enrolled_liveEvents: 'Live-Veranstaltungen',
    sharebot: 'Sharebot',
    selected_language: 'Ausgewählte Sprache',
    shareSphere_game: 'ShareSphere-Spiel',
    maze: 'MetaMaze',
    skill: 'Skill Journey',
    channel: 'Kanal',
    order: 'Bestellung',
    is_record: 'Aufzeichnen',
    no_maze_found: 'Keine MetaMaze gefunden.',
    maze_self_limit_reached: 'MetaMaze-Selbstbewertungsgrenze erreicht.',
    metamaze_two_times: 'Sie haben dieses MetaMaze bereits 2 Mal gemacht.',
    metamaze_two_times_no_result:
      'Sie haben dieses MetaMaze bereits 2 Mal gemacht. Ihr Ergebnis wird nach dem zweiten Mal nicht mehr verfolgt.',
    scorm_zip: 'SCORM ZIP',
    pass_score: 'Mindestpunktzahl zum Bestehen',
    organization: 'Bitte wählen Sie eine Organisation aus',
    pass_score_mark: 'Bitte Punktzahl zwischen 1 und 100 eingeben',

    maze_cloning_success: 'MetaMaze "<0/>" wurde erfolgreich geklont!',
    create_certificate: 'Zertifikat erstellen',
    create_badge: 'Abzeichen erstellen',
    certificate_added: 'Zertifikat hinzugefügt.',
    badge_added: 'Abzeichen hinzugefügt.',
    certificate_warning:
      'Sie können das Zertifikat/Abzeichen nicht mehr bearbeiten, sobald es veröffentlicht ist!',
    created_course_plan:
      'Bearbeiten und veröffentlichen Sie Ihren erstellten Kurs und planen Sie Live-Veranstaltungen',
    market_place_space: 'Marktplatz',
    select_course_language: 'Kurs-Sprache auswählen (optional)',
    select_language: 'Sprache auswählen',
    file_upload: 'Datei hochladen',
    text: 'Hier Text eingeben...',
    attempts_count:
      'Anzahl der Versuche, die ein Benutzer erneut versuchen kann',
    no_of_people_placeholder: 'Anzahl der Personen',

    back_to_maze: 'Zurück zum MetaMaze-Szenario',
    edit_maze: 'Maze bearbeiten',
    wait: 'Warten...',
    retry: 'Erneut versuchen',
    publish_scorm_course: 'Ihren SCORM-Kurs veröffentlichen',
    publish_maze: 'Ihren MetaMaze veröffentlichen',
    import: 'Elemente importieren',

    answer_correct: 'Wählen Sie die richtige Antwort',
    author_name: `Bitte geben Sie den Namen des Autors ein`,
    select_category: 'Bitte wählen Sie eine Kategorie aus',
    course_outline: 'Bitte Kursübersicht eingeben',
    course_obj: 'Bitte Kursziele eingeben',
    course_description: 'Bitte Kursbeschreibung eingeben',
    maze_outline: 'Bitte Maze-Übersicht eingeben',
    maze_obj: 'Bitte Maze-Ziele eingeben',
    maze_description: 'Bitte Maze-Beschreibung eingeben',
    lessons: 'Bitte Anzahl der Lektionen eingeben',
    user_email_require: `Benutzer-E-Mail ist erforderlich`,
    team_size: `Teamgröße ist erforderlich`,
    enter_price: 'Bitte geben Sie einen Preis ein (5 oder mehr)',
    input_title_maze: 'Bitte geben Sie den Maze-Titel ein',
    trainer_bio: 'Bitte geben Sie die Biografie des Trainers ein',
    trainer_linkedin: 'Bitte geben Sie das LinkedIn-Profil des Trainers ein',
    error_video: 'Bitte Anzahl der Videos eingeben',
    input_video_link: 'Bitte Videolink eingeben',
    number_of_video: 'Anzahl der Videos',
    number_of_lessions: 'Anzahl der Lektionen',

    no_of_people_in_conference: 'Wie viele Personen in der Konferenz?',
    transcribe_settings: 'Transkriptions-Einstellungen',
    transcribe: 'Transkribieren',
    cloning_maze: 'MetaMaze wird geklont...',
    id_number: 'ID-Nummer',
    timezone: 'Zeitzone',
    edit_maze: 'Bearbeiten',
    delete_maze: 'Löschen',
    view_maze: 'Ansehen',
    create_metamaze: 'Ein MetaMaze erstellen',
    metamaze_scenario: 'MetaMaze-Szenario',
    publish_metamaze: 'Veröffentlichungseinstellungen',
    search_result: 'Suchergebnisse:',
    search_for: 'Suchen nach',
    search_no_result: '- Keine Ergebnisse verfügbar!',
    channel_no_available: 'Kein Kanal verfügbar!',
    this_card: 'Dies ist eine Karte',
    course_no_available: 'Keine Kurse verfügbar!',
    manage_sequence: 'Sequenz verwalten',
    save_sequence: 'Sequenz speichern',
    new: 'Neu',
    in_progress: 'In Bearbeitung',
    recommended_course: 'Empfohlener Kurs',
    up_coming: 'Bevorstehend',
    past_live: 'Vergangene Live-Events',
    input_your_channel_name: 'Bitte geben Sie den Namen Ihres Kanals ein!',
    organization_place: 'Organisationsmarktplatz',
    marketplace_report: 'Marketplace-Bericht',
    story_writing: 'Geschichten schreiben',
    Ai_quiz: 'KI-Quiz',
    generate_quiz: 'Quiz generieren',
    create_story: 'Geschichte erstellen',
    change_layout_page: 'Sie sind dabei, das Layout dieser Seite zu ändern.',
    change_layout_message:
      'Bitte beachten Sie, dass nur die Textelemente, die von der KI-Erstellung generiert wurden, beibehalten werden. Alle anderen Elemente werden von der Seite entfernt.',
    reviews: 'Bewertungen',
    tab_attachments: 'Anhänge',
    zoom_in: 'Vergrößern',
    zoom_out: 'Verkleinern',
    zoom_reset: 'Zoom zurücksetzen',
    no_attachment: 'Keine Anhänge.',
    select_upload_simulation: 'Bitte Simulation auswählen/hochladen.',
    quiz_updated_successfully: 'Quiz erfolgreich aktualisiert!',
    quizes: 'Quizze',
    trivia: 'Wissenswertes: ',
    closing: 'Schließen...',
    products: 'Produkte',
    ask_questions: 'Stellen Sie hier eine Frage...',

    course_information: 'Kursinformationen',
    go_back: 'Zurück',
    ai_generate_content_progress: 'Inhaltserstellung Fortschritt',
    view_generate_content: 'Generierte Inhalte anzeigen',
    generate_error: 'Fehler bei der Generierung!',
    confirm_creating_course:
      'Möchten Sie die Erstellung des Kurses mit diesem Inhalt bestätigen?',
    generate_content_automatically:
      'Dies wird automatisch alle generierten Inhalte als Lektionen und Seiten zu Ihrem Kurs hinzufügen.',
    quiz_created_successfully: 'Quiz erfolgreich erstellt!',
    create_quiz: 'Quiz erstellen',
    quiz_setting: 'Quiz-Einstellungen',
    please_fill_quiz_setting:
      '* Bitte füllen Sie alle Felder in den Quiz-Einstellungen aus',
    question_list: 'Fragenliste',
    add_quiz_to_page: 'Quiz zur Seite hinzufügen',
    form_create_question: 'Formular: Eine Frage erstellen',
    user_information_updated: 'Benutzerinformationen aktualisiert!',
    your_stripe_activete_to_continue:
      'Ihr Stripe-Konto ist inaktiv. Bitte verbinden oder aktivieren Sie es, um fortzufahren.',
    connect_stripe: 'Stripe verbinden',
    certificate_sent_sucessfully:
      'Zertifikat erfolgreich an Ihre E-Mail gesendet.',
    error_try_again: 'Fehler. Versuchen Sie es erneut!',
    error_delete_channel: 'Fehler beim Löschen des Kanals.',
    error_update_sub: 'Fehler beim Aktualisieren des Abonnements.',
    end_time_cannot_start: 'Endzeit kann nicht vor der Startzeit liegen.',
    start_date_time_interval: 'Startdatum und Zeitintervall sind erforderlich!',
    podcast_updated_successful: 'Podcast erfolgreich aktualisiert.',
    podcast_created_successful: 'Podcast erfolgreich erstellt.',
    podcast_published_successful: 'Podcast erfolgreich veröffentlicht.',
    podcast_cover_updated: 'Podcast-Cover aktualisiert',
    new_podcast_details:
      'Neuer Podcast wird mit den aktuellen Eingabedetails auf dem bestehenden Podcast erstellt.',
    current_update_podcast: 'Aktuelle Podcast-Details aktualisieren.',
    create_new_podcast: 'Neuen Podcast erstellen',
    publish_podcast_public_view:
      'Podcast für die öffentliche Ansicht veröffentlichen.',
    only_allowed_twenty_five_email: 'Es sind nur 25 E-Mails erlaubt.',
    only_allowed_five_email: 'Es sind nur 5 E-Mails erlaubt.',
    cover_is_required: 'Cover ist erforderlich.',
    topic_is_required: 'Thema ist erforderlich.',
    delete_schedule: 'Zeitplan löschen',
    make_as_coaching: 'Als Coaching-Verfügbarkeit markieren',
    manage_category: 'Kategorie verwalten',
    threshold: 'Schwellenwert',
    threshold_popup:
      'Schwellenwert in Prozent (%), der Wert, nach dem die Empfehlung für die gegebene Kategorie aktiviert wird.',
    list_of_catagory: 'Liste der Kategorien',
    category_name: 'Kategoriename',
    category_threshold: 'Kategorie-Schwellenwert',
    play_game: 'Spiel spielen',

    switching_organization: 'Organisation wechseln...',
    coaching_session: 'Coaching Sitzung',
    required_channel_id: 'Kanal-ID ist erforderlich!',
    error_occured_try_again:
      'Ein Fehler ist aufgetreten. Versuchen Sie es erneut!',
    please_complete_marketplace_form:
      'Bitte füllen Sie das Marktplatzformular aus!',
    upload_file_corrupt: 'Hochgeladene Datei ist beschädigt.',
    course_not_published: 'Kurs ist noch nicht veröffentlicht!',
    error_request: 'Fehler bei der Anfrage. Bitte versuchen Sie es erneut!',
    error_occured: 'Ein Fehler ist aufgetreten. Versuchen Sie es erneut!',
    show_time: 'Uhrzeit anzeigen',
    start_time: 'Startzeit',
    end_time: 'Endzeit',
    room_name: 'Raumname',
    add_guest_speaker: 'Gastredner hinzufügen',
    cover_picture: 'Titelbild',
    guest_speaker: 'Gastredner',
    see_more: ' Mehr anzeigen',
    see_less: ' Weniger anzeigen',
    background: 'Hintergrund',
    icon: 'Symbol',
    icon_size: 'Symbolgröße',
    icon_color: 'Symbolfarbe',
    only_text: 'Text',
    change_bg: 'Hintergrundfarbe ändern',
    bg_color: ' Hintergrundfarbe',
    font_size: 'Schriftgröße',
    normal: 'Normal',
    change_color: 'Textfarbe ändern',
    slant_text: 'Text neigen',
    text_radius: 'Textradius',
    vertical_align: 'Vertikale Ausrichtung',
    horizontal_align: 'Horizontale Ausrichtung',
    change_icon_color: 'Symbolfarbe ändern',
    upload_icon: 'Symbol hochladen',
    upload_signature: 'Unterschrift hochladen',
    add_another_sign: 'Weitere Unterschrift hinzufügen',
    name_badges: 'Name der Abzeichen',
    should_less_150: ' sollte 150 Zeichen oder weniger sein',
    your_badge: 'Ihr Abzeichen',
    do_you_want_delete_badge: 'Möchten Sie dieses Abzeichen wirklich löschen?',
    do_you_want_delete_cert: 'Möchten Sie dieses Zertifikat wirklich löschen?',
    upload_logo: 'Logo hochladen',
    name_of_cert: 'Name des Zertifikats',
    upload_cert: 'Zertifikat aktualisieren',
    your_cert: 'Ihr Zertifikat',
    translate_to: 'Übersetzen in',
    select_file_attach: 'Datei zum Anhängen auswählen',
    certificate: 'Zertifikat',
    badge: 'Abzeichen',
    publish_options: 'Veröffentlichungsoptionen',
    publish_skills_journey: 'Fähigkeiten-Reise veröffentlichen',
    videos: 'Videos',
    generate_ai_video:
      'Der Videogenerierungsprozess kann einige Zeit dauern. Sie werden per E-Mail benachrichtigt, sobald er abgeschlossen ist.',
    trainer_information: 'Trainerinformationen',
    promotional_video_link: 'Link zum Werbevideo',
    please_select: 'Bitte auswählen',
    image_name: 'Bildname:',
    image_size: 'Bildgröße:',
    charecters_fifteen_less: '15 Zeichen oder weniger',
    leadership: 'Führung',
    text_arc: 'Textbogen',
    vertical: 'Vertikal',
    horizontal: 'Horizontal',
    badge_history: 'Abzeichenhistorie',
    templates: 'Vorlagen',
    sign: 'Unterschrift',
    logo: 'Logo',
    certificate_history: 'Zertifikatsgeschichte',
    please_wait: 'Bitte warten...',
    information: 'Information',
    support_five_thousand_live_event:
      'Unterstützt bis zu 5000 Teilnehmer für die Veranstaltung',
    support_ten_live_event: 'Unterstützen Sie bis zu 10 Live-Video-Teilnehmer',
    we_will_back_participant:
      'Wir werden mit der Veranstaltung zurückkommen und Zugang zu einer großen Teilnehmerzahl haben.',
    course_objectives: 'Kursziele',
    course_outlines: 'Kursübersicht'
  },

  v3: {
    reportShareSphere: 'SHARESPHERE',
    overview: 'Kategorie Übersicht',
    triviaReport: 'ShareSphere Kategorie',
    overviewGraph: 'Kategorieübersicht Diagramm',
    category: 'Kategorie',
    phCategory: 'Kategorie auswählen',
     podcast: 'Podcast und Musik',
    pastPodcast: 'Ältere Podcasts',
    upcomingPodcast: 'Kommende Podcasts',
    podcastLanguageTitle: 'Podcast Sprache',
    podcastLanguageCheckbox: 'Generiere Audio für meinen Podcast mithilfe KI',
    howRate: 'Wie würden Sie diesen Podcast bewerten?',
    podcastSubmit: 'Absenden',
    podcastGuest: 'Gastsprecher',
    podcastReview: 'Bewertungen',
    podcastEpishode: 'Episoden',
    podcastEnd: 'Podcast hat geendet',
    date_podcast: 'Podcast Datum',
    skill_not_found: 'Entschuldigung ! Keine Fähigkeitsreise wurde gefunden.',
    coaching: 'Coaching',
    webinar: 'Webinar',
    public_conference: 'Öffentliche Konferenz',
    skill_journey_subtitle:
      'Fügen Sie Einstellungen zu Ihrer Fähigkeitsreise hinzu und wählen Sie aus, wer Ihre Fähigkeitsreise sehen und mit ihr interagieren kann.',
    enable_white_label: 'White Label aktivieren',
    create_new_event_org: 'Neues Event für Ihre Organisation erstellen.',
    create_new_event_marketplace: 'Neues Event für den Marktplatz erstellen.',

    create_new_event_both:
      'Neues Event für sowohl den Marktplatz als auch Ihre Organisation erstellen.',
    event_outline: 'Bitte Event Kurzübersicht einfügen',
    event_obj: 'Bitte Eventziele einfügen',
    event_description: 'Bitte Eventbeschreibung einfügen',
    create_new_metamaze_org: 'Neues MetaMaze für Ihre Organisations erstellen.',
    create_new_metamaze_marketplace:
      'Neues MetaMaze für den Marktplatz erstellen.',
    create_new_metamaze_both:
      'Neues MetaMaze für sowohl den Marktplatz als auch Ihre Organisation erstellen.',
    add_key_words: 'Keywords hinzufügen',
    press_enter_make_keyword:
      'Drücken Sie die Enter, um ein Keyword zu erstellen',
    disable_skip_quiz: 'Quiz überspringen deaktivieren',
    warning_complete_quiz: 'Bitte absolvieren Sie das Quiz, um fortzufahren.',
    signup_email_verification:
      'Wir haben einen Bestätigungslink an die von Ihnen angegebene E-Mail-Adresse gesendet. Bitte besuchen Sie den Link, um Ihre Registrierung zu bestätigen..',
    verifying_registration: 'Kontoanmeldung wird verifiziert...',
    reset_password_linK:
      'Wir haben einen Link an die von Ihnen angegebene E-Mail-Adresse gesendet. Bitte besuchen Sie diesen Link, um ein neues Passwort festzulegen.',
    reset_email_error:
      'Benutzer mit dieser E-Mail nicht gefunden, bitte versuchen Sie es stattdessen mit dem Benutzernamen!',
    first_name: 'Vorname',
    last_name: 'Nachname',
    error_firstName: 'Bitte geben Sie Ihren Vornamen ein',
    error_sharebot_organization: 'Bitte geben Sie Ihre Organisation ein',
    error_lastName: 'Bitte geben Sie Ihren Nachnamen ein',
    private_conference: 'Private Konferenz',
    public_conference: 'Öffentliche Konferenz',
    discussion_room: 'Diskussionsraum',
    training_no_courses: 'Sie sind derzeit in keine Kurse eingeschrieben.',
    training_no_events:
      'Sie sind derzeit in keine Veranstaltungen eingeschrieben.',
    allow_sharing_trainer_info:
      'Erlauben Sie das Teilen der E-Mail-Adresse im Marktplatz',
    error_username_space: `Benutzername darf keine Leerzeichen enthalten.`,
    error_sharebot_organization_space: `Organisation darf keine Leerzeichen enthalten.`,
    edit_permission: 'Bearbeitungsberechtigung',
    valid_price: 'Der Preis muss größer als 0 sein',
    course_price_message: `*Der Kurs wird nur an die Anzahl der Benutzer veröffentlicht, die die Organisation gekauft hat, und kann nicht auf dem Marktplatz weiterverkauft werden.`,
    valid_edit_price:
      'Preis für die Bearbeitung muss geringer sein als der Kaufpreis.',
    my_calendar: 'Mein Kalender',
    error_valid_firstName: 'Bitte geben Sie einen gültigen Vornamen ein.',
    error_valid_organization:
      'Bitte geben Sie einen gültigen Organisationsnamen ein.',
    error_valid_lastName: 'Bitte geben Sie einen gültigen Nachnamen ein.',
    error_name_spaces: `Name darf keine Leerzeichen enthalten.`,
    items: 'Artikel',
    publish_to_channel: 'In meiner Kanal Kollektion veröffentlichen',
    create_a_channel: 'Meinen Kanal erstellen',
    create_a_trivia: 'Trivia erstellen',
    create_a_new_channel: 'Einen neuen Kanal erstellen',
    add_videos: 'Videos hinzufügen',
    save_and_continue: 'Speichern und Fortfahren',
    channel: 'Kanal',
    channels: 'Kanäle',
    view_channel: 'Kanal anzeigen',
    edit_channel: 'Kanal bearbeiten',
    delete_channel: 'Kanal löschen',
    my_video_channels: 'Meine Videokanäle',
    training_no_channel: 'Sie sind derzeit in keinen Kanal eingeschrieben.',
    monthly_price_required: 'Monatspreis erforderlich',
    yearly_price_required: 'Jahrespreis erforderlich',
    one_time_price_required: 'Einmaliger Preis erforderlich',
    marketplace_quantity: 'Menge',
    report_webinar: 'WEBINAR',
    report_coaching: 'COACHING',
    report_channel: 'KANAL',
    report_public_conference: 'ÖFFENTLICHE KONFERENZ',
    report_maze: 'MAZE',
    edit_price: 'Preis bearbeiten ($)',
    quantity: 'Menge',
    payment_type: 'Zahlungsart',
    total_amount: 'Gesamtbetrag ($)',
    net_amount: 'Nettobetrag ($)',
    edit_option: 'Bearbeitungsoption',
    marketplace_price: 'Preis ($)',
    market_name: 'Name',
    duration_min: 'Dauer (Min)',
    users_total: 'Benutzer (Insgesamt)',
    sales_total: 'Verkaufsgesamtbetrag (USD)',

    search_coaching: 'Coaching suchen',
    search_channel: 'Kanal suchen',
    search_webinar: 'Webinar suchen',
    search_maze: 'Maze suchen',
    search_public_conference: 'Öffentliche Konferenz suchen',
    buyer_type: 'Käufertyp',
    quanity: 'Menge',
    channel_unit_amount: 'Nettobetrag ($)',
    channel_updated_at: 'Aktualisierungsdatum',
    month_price: 'Preis (Monat)',
    year_price: 'Preis (Jahr)',
    one_time_price: 'Preis (Einmalig)',
    sharebot_organization: 'Neuen Organisationsnamen hinzufügen',
    share_sphere: 'ShareSphere',
    ai_course_title: 'Kurstitel',
    ai_input_course_title: 'Bitte geben Sie den Kurstitel ein!',
    learning_objective_label: 'Lernziel',
    learning_objective_message: 'Bitte geben Sie das Lernziel ein!',
    standard_question: 'Gibt es einen Standard, dem der Lernende folgen muss?',
    standard_details_label: 'Standarddetails',
    standard_details_message: 'Bitte geben Sie die Standarddetails ein!',
    estimated_duration_label: 'Geschätzte Kursdauer (in Minuten)',
    estimated_duration_message: 'Bitte geben Sie die geschätzte Dauer ein!',
    min_duration_message: 'Die Mindestdauer beträgt 5 Minuten.',
    ai_mins: '25 Minuten',
    industry_specific_question: 'Ist es branchenspezifisch?',
    industry_label: 'Branchenspezifizierung',
    industry_message: 'Bitte geben Sie die Branche ein!',
    ai_course_creation: 'KI Kurs Erstellung',
    course_generated: 'Kurs generiert von Sharelook',
    ai: 'KI',
    ai_you: 'Sie',
    ai_prompt_ready:
      'Ihr Prompt ist bereit. Sie sind nun bereit, das Inhaltsverzeichnis zu generieren.',
    ai_unable_respond:
      'Entschuldigung! Die KI kann nicht auf Ihren Prompt antworten. Bitte versuchen Sie es erneut.',
    ai_hide_toc: 'Inhaltsverzeichnis ausblenden',
    ai_preview_toc: 'Vorschau des Inhaltsverzeichnisses',
    ai_generate_toc_now: 'Jetzt Inhaltsverzeichnis generieren',
    ai_creation_course_title: 'Kurstitel:',

    ai_creation_industry_specific: 'Ist es branchenspezifisch:',
    ai_creation_industry: 'Branche:',
    ai_creation_standard_needed:
      'Gibt es einen Standard, dem der Lernende folgen muss?:',
    ai_creation_standard_details: 'Standarddetails:',
    ai_creation_estimated_duration: 'Geschätzte Kursdauer (in Minuten):',
    minutes: 'Minuten',
    ai_creation_learning_objective: 'Lernziel:',
    setup_again: 'Erneut erstellen',
    ai_creation_confirm_title:
      'Möchten Sie das Formular wirklich erneut erstellen?',
    ai_creation_confirm_content:
      'Der Chat und alle Inhalte, die Sie generiert haben, werden zurückgesetzt.',
    ai_toc: 'Inhaltsverzeichnis',
    ai_note_topic:
      'Hinweis: Sie können Themen innerhalb der Lektion per Drag & Drop neu anordnen.',
    ai_add_topic: '+ Thema hinzufügen',
    add_topic: 'Thema hinzufügen',
    add_lesson: 'Lektion hinzufügen',
    add_new_lesson: 'Neue Lektion hinzufügen',
    ai_add_lesson: '+ Lektion hinzufügen',
    attention: 'Achtung',
    before_varifying: 'Vor der Überprüfung',
    ai_save_later: 'Für später speichern',
    ai_download_word_doc: 'Als Word-Dokument herunterladen',
    ai_download_course_word_doc: 'Kurs als Word-Dokument herunterladen',
    ai_create_course_button: 'Kurs jetzt erstellen',
    ai_generate_course_content: 'Kursinhalt generieren',
    ai_out_of: 'von',
    ai_subtopic_failed_content:
      'Unterthemen konnten nicht generiert werden. Klicken Sie erneut auf "Kursinhalt generieren", um nur für diese Unterthemen Inhalte zu generieren.',
    ai_edit_topic: 'Thema bearbeiten',
    ai_edit_lesson_title: 'Lektionstitel bearbeiten',
    ai_save_topic: 'Thema speichern',
    ai_save: 'Speichern',
    ai_save_lesson_title: 'Lektionstitel speichern',
    ai_add_new_topic: 'Neues Thema hinzufügen',
    ai_download_ref: 'Mit Referenzen herunterladen',
    ai_download_ref_content:
      'Es wird der Kurs als Word-Dokument mit Referenzen heruntergeladen.',

    ai_download_without_ref: 'Download ohne Referenzen',
    ai_download_two_word: 'Es werden zwei Word-Dokumente heruntergeladen:',
    ai_download_two_word_content_one:
      '- eins mit dem Hauptinhalt ohne Referenzen,',
    ai_download_two_word_content_two:
      'und das andere besteht nur aus einer Liste von Referenzen ohne begleitenden Inhalt.',
    ai_manage_content: 'Inhalt verwalten',
    ai_add_content_from_internet: '+ Inhalt aus dem Internet hinzufügen',
    ai_confirm_start: 'Start bestätigen',
    ai_Verify: 'Überprüfen',
    ai_undo_warning:
      'Dieser Vorgang kann einige Zeit in Anspruch nehmen. Bitte schließen Sie dieses Fenster nicht, bis die Generierung abgeschlossen ist, da sie nicht rückgängig gemacht werden kann.',
    ai_verified_content:
      'Sie haben den Inhalt überprüft. Beachten Sie, dass Sie nach der Bestätigung nicht mehr mit dem KI-Assistenten chatten können.',
    ai_want_proceed: 'Möchten Sie fortfahren?',
    upload_simulation: 'Simulation hochladen',
    name_simulation: 'Simulationsname',
    selected_simulation_files: 'Ausgewählte Simulationsdateien',
    score_between_one_hundred:
      'Ihre Punktzahl sollte zwischen 1 und 100 liegen.',
    are_sure_simulation: 'Möchten Sie diese Simulationsdatei wirklich löschen?',
    required_simulation: 'Punktzahl ist erforderlich',
    simulation: 'Simulation',
    simulation_quiz_games: 'Quiz in Spielen hinzufügen',
    simulation_alert_des:
      'Sie können Spiele nur hinzufügen, wenn alle Fragen eine Antwortart von entweder Einzel- oder Mehrfachauswahl haben.',
    alert_info: 'Info',
    ai_number_quiz: 'Anzahl der Quizfragen',
    ai_point: 'Punkt',
    ai_points: 'Punkte',
    val_required: 'Erforderlich',
    games: 'Spiele',
    add_simulation: 'Simulation hinzufügen',
    text_tiewer: 'Textanzeiger',
    back_to_account: 'Zurück zum Konto',
    delete_account: 'Konto löschen',
    delete_account_title: 'Konto löschen?',
    delete_account_subtitle: 'Sie können Ihr Konto hier löschen.',
    delete_account_cannot_undo:
      'Sie sind dabei, Ihr Konto zu löschen. Diese Aktion kann nicht rückgängig gemacht werden.',

    account_org_profile:
      'Sie verlieren auch alle von Ihnen erstellten Kurse, Ihr Organisationsprofil und alle Ihre Einstellungen.',
    deleting_account_database:
      'Das Löschen Ihres Kontos entfernt alle Ihre Daten aus unserer Datenbank.',
    account_confirm_delete: 'Möchten Sie Ihr Konto wirklich löschen?',
    action_cannot_be_undone:
      'Diese Aktion kann nicht rückgängig gemacht werden.',
    account_delete_message_success: 'Ihr Konto wurde erfolgreich gelöscht.',
    message_edit: 'Bearbeiten',
    message_delete: 'Löschen',
    message_reply: 'Antworten',
    attached_file: 'Angehängte Datei',
    attach_file: 'Datei anhängen',
    attach_des: '25 MB maximale Dateigröße',
    view: 'Ansehen',
    go_to_marketplace: 'Zum Marktplatz gehen',
    learning_center: 'Lernzentrum',
    knowledge_center: 'Wissenszentrum',
    sharebot: 'ShareBot',
    sharebot_help_message: 'Wie kann ich Ihnen heute helfen?',
    my_organization: 'Meine Organisation',
    world_knowledge: 'Weltwissen',
    welcome_to_sharelook: 'Willkommen bei Sharelook!',
    sharelook_you_want_go: 'Wohin möchten Sie heute gehen?',
    shareLook_learning_management_system:
      'Zugang zum ShareLook Learning Management System, um Lernressourcen wie Live-Events und Kurse in Ihrer Organisation auf ShareLook zu erstellen oder darauf zuzugreifen.',
    shareLook_powerful_ai_system:
      'Zugang zum leistungsstarken KI-gesteuerten System von ShareLook, wo Sie PDF-Dokumente hochladen und innerhalb von Sekunden nützliche Erkenntnisse erhalten können. Erhalten Sie schnelle Antworten basierend auf den hochgeladenen Ressourcen in Ihrer Organisation oder erhalten Sie Antworten von unserem allgemeinen KI-System im Bereich Weltwissen.',
    edit_now: 'Jetzt bearbeiten',
    sharelook_log_out:
      'Sie wurden von Sharelook abgemeldet. Bitte melden Sie sich erneut an!',
    sorry_error: 'Entschuldigung! Es ist ein Fehler aufgetreten.',
    login: 'Anmelden',
    refresh_page: 'Seite aktualisieren',
    just_a_moment: 'Einen Moment bitte',
    enter_lname_fname:
      'Bitte geben Sie Ihren Vor- und Nachnamen ein, um fortzufahren.',

    procced_to_sharelook: 'Zu Sharelook gehen',
    option_one: 'Option 1',
    option_two: 'Option 2',
    selected_image: 'Ausgewähltes Bild:',
    add_text: 'Text hinzufügen',
    linkdin_is_required: 'Linkedin ist erforderlich.',
    bio_is_required: 'Bio ist erforderlich.',
    save_user_information: 'Benutzerinformationen speichern',
    select_all: 'Alle auswählen',
    enable_ai: 'KI aktivieren',
    load_more: 'Mehr laden',
    channel_id: 'Kanal ID.',
    send: 'Senden',
    v4: 'Informationen',
    subscription: 'Abonnement',
    reset_user_flag: 'BENUTZERFLAGGE ZURÜCKSETZEN',
    delete_my_accont: 'Mein Konto löschen',
    sharebot_sub_error:
      'Fehler beim Abrufen von Abonnements. Versuchen Sie es erneut!',
    sharebot_no_sub: 'Sie haben derzeit keine aktiven Abonnements.',
    sharebot_dont_sub: 'Sie haben keine früheren Abonnements.',
    sharebot_prev_sub: 'Früher abonniert',
    sharebot_sub: 'ShareBot-Abonnement',
    sharebot_un_sub: 'ShareBot-Abonnement kündigen',
    sharebot_no_available: 'Keine Beschreibung verfügbar',
    sharebot_start_date: 'Startdatum:',
    sharebot_end_date: 'Enddatum:',
    sharebot_sure: 'Sind Sie sicher?',
    sharebot_do_you_want: 'Möchten Sie',
    unsubscribe: 'abbestellen',
    unsubscribe_what: 'abbestellen?',
    subscribe: 'abonnieren',
    subscribe_what: 'abonnieren?',
    subscribe_now: 'Jetzt abonnieren',
    confirm: 'Bestätigen',
    sharebot_intel_knowledge_center: "ShareBot's Intelligentes Wissenszentrum",
    sharebot_welcome_paragraph:
      'Willkommen im Wissenszentrum von ShareBot, dem fortschrittlichen KI-Chatbot, der darauf ausgelegt ist, die Art mit der Profis auf Informationen zugreifen und zusammenarbeiten zu verbessern.',
    expired: 'Abgelaufen',
    active: 'Aktiv',

    Inactive: 'Inaktiv',
    yearly_subscription: 'Jahresabonnement',
    monthly_subscription: 'Monatsabonnement',
    no_sharebot_org: 'Sie haben keine ShareBot-Organisation',
    sharebot_start_free_trial: 'Kostenlose Testversion starten',
    sub_month: 'Abonnement monatlich',
    sub_year: 'Abonnement jährlich',
    question_per_month: 'Daten speicher (1 TB) und 1000 Fragen pro Monat',
    sharebot_para:
      'Wenn Sie fortfahren, bestätigen Sie die sofortige Löschung der Organisation. Diese Aktion führt zum Verlust aller wertvollen Einblicke, die von ShareBot bereitgestellt wurden, und kann nicht rückgängig gemacht werden.',
    cancelled_date: 'Stornierungsdatum:',
    live_straming_para:
      'Wenn Sie Ihr monatliches Live-Streaming-Limit ändern möchten, kontaktieren Sie uns bitte',
    sharelook_agree: 'Ich gebe meine Zustimmung für Sharelooks',
    terms_condition: 'Nutzungsbedingungen',
    and: 'und',
    privacy_policy: 'Datenschutzbestimmungen',
    save_for_later: 'Für später speichern',
    ai: 'KI',
    no_video_yet: 'Noch keine Videos ausgewählt!',
    are_you_sure_delete_video: 'Möchten Sie dieses Video wirklich löschen?',
    video_des: 'Beschreibung',
    video_now: 'Jetzt',
    video_playing: 'Wiedergabe:',
    video_no: 'Video Nr.',
    ai_generated_course: 'Von KI generierter Kurs',
    create_course_on_our_own: 'Erstellen Sie einen Kurs selbst',
    ai_Assisted_ourse_reation: 'KI-unterstützte Kurs-Erstellung',
    pending: 'Ausstehend',
    published: 'Veröffentlicht',
    intro_course: 'in Ihren Kurs?',
    clone_course: 'Möchten Sie den Kurs klonen',
    browser_audio_not_support:
      'Ihr Browser unterstützt die Audio-Flagge nicht.',
    audio_popup_content:
      'Hier können Sie viele Sprachstile über den von Ihnen eingegebenen Text verwenden bla Bla bla Lorem Ipsum ist einfach dummy text aus dem Druck- und Satzgewerbe. Lorem Ipsum ist der Standarddummy-Text der Branche seit den 1500er Jahren,',
    close: 'Schließen',

    podcast_rec: 'REC',
    create_podcast: 'Podcast erstellen',
    add_admin: 'ADMIN HINZUFÜGEN',
    delete_all: 'Alle löschen',
    ai_podcast_audio: 'KI Podcast Audio',
    language: 'Sprache',
    podcast_download_content:
      '*Laden Sie die KI Audiodatei herunter, um sie zu bearbeiten und zu Ihrer eigenen Audiodatei zu machen.',
    upload_audio: 'Audio hochladen',
    browser_do_not_support_audio:
      'Ihr Browser unterstützt das Audioelement nicht.',
    upload_cover: 'Cover hochladen',
    upload_ai_creator: 'KI-Ersteller',
    required_cover_image: 'Coverbild ist erforderlich!',
    room_required_name: 'Raumname ist erforderlich!',
    add_guest_list: 'Bitte fügen Sie Gästelisten hinzu!',
    podcast_add_participants: 'TEILNEHMER HINZUFÜGEN',
    topic_required: 'Mindestens ein Thema ist erforderlich',
    podcast_description: "BESCHREIBUNG",
    podcast_date: 'DATUM',
    date: 'Datum',
    podcast_time: 'Uhrzeit',
    podcast_to: 'bis',
    podcast_all_day: 'Den ganzen Tag',
    podcast_start_date_time: 'Startdatum und -zeit sind erforderlich!',
    podcast_id: 'ID',
    selected_user: 'Ausgewählte Benutzer einladen',
    see_more: 'Mehr anzeigen',
    see_less: 'Weniger anzeigen',
    no_review_yet:
      'Noch keine Bewertung, seien Sie der Erste, der eine abgibt.',
    request_to_speak: 'Benutzeranfrage zum Sprechen annehmen?',
    kick_user: 'Möchten Sie diesen Benutzer rauswerfen?',
    accept_request: 'Anfrage annehmen',
    kick: 'RAUSWERFEN',
    started: 'GESTARTET',
    end: 'ENDE',
    join_now: 'Jetzt beitreten',
    podcast_end: 'Der Podcast ist zu Ende',
    connecting: 'Verbinden...',
    knowledge_center_know_today: 'Was möchten Sie heute wissen?',

    knowledge_center_question: 'Bitte geben Sie zuerst eine Frage ein',
    knowledge_center_error: 'Entschuldigung! Es ist ein Fehler aufgetreten.',
    knowledge_center_tooltrip:
      'Erhalten Sie schnelle Antworten aus den Lernressourcen im Repository Ihrer Organisation auf ShareLook, mit korrekten Referenzen und der Möglichkeit, die referenzierten PDF-Dokumente zu überprüfen und herunterzuladen.',
    my_organization: 'Meine Organisation',
    world_knowledge: 'Weltwissen',
    open_file: 'Datei öffnen',
    reference: 'Referenz',
    are_you_cancel: 'Sind Sie sicher, dass Sie abbrechen möchten?',
    are_you_remove_media:
      'Sind Sie sicher, dass Sie die Mediendatei entfernen möchten?',
    terms_agree: 'Ich stimme diesen zu',
    ready: 'Bereit',
    send_email: 'E-MAIL SENDEN',
    active_users: 'Aktive Benutzer',
    sign_up_user: 'Benutzer anmelden',
    non_sign_up_user: 'Nicht angemeldete Benutzer',
    organisation: 'Organisation',
    usage_report: 'Nutzungsbericht',
    check_email: 'Überprüfen Sie Ihre E-Mails',
    delete_schedule: 'Zeitplan löschen',
    are_you_sure_delete_schedule:
      'Sind Sie sicher, dass Sie diesen Zeitplan löschen möchten?',
    add_task: 'Bitte geben Sie eine Aufgabe ein!',
    coaching_available: 'Als Coaching-Verfügbarkeit markieren',
    message_booking_coaching:
      'Dieser Zeitraum wurde von einem Benutzer für ein Coaching gebucht.',
    task_day: 'Aufgabentag',
    created_task_day: 'Sie haben eine Aufgabe für diesen Tag erstellt.',
    event_day: 'Veranstaltungstag',
    has_booked_coaching: 'Ein Coaching wurde für diesen Tag gebucht.',
    active_day: 'Aktiver Tag',
    selectable_time_slots:
      'Die auswählbaren Zeitfenster rechts sind für diesen Tag aktiv.',
    compare_last_month: 'Im Vergleich zum letzten Monat',
    uploading_file: 'Ihre Datei wird hochgeladen',
    attach_file_course: 'An diesen Kurs angehängt.',
    fetch_request: 'Ihre Anfrage wird abgerufen',
    finilize_upload: 'Upload abschließen',
    scorm_scanned:
      'Bitte warten... Ihre hochgeladene SCORM-Zip-Datei wird gescannt.',

    scan_status: 'Scan Status:',
    scan_completed: 'Scan Abgeschlossen. Ihre Datei ist',
    scan_progress: 'Scan läuft...',
    check_scorm: 'Überprüfung des SCORM-Dateiformats... Bitte warten...',
    file_check_success: 'Dateiüberprüfung erfolgreich',
    file_check_progress: 'Dateiüberprüfung läuft...',
    scorm_not_upload:
      'Ihre hochgeladene Datei entspricht nicht dem SCORM 1.2-Standard. Bitte laden Sie die Datei erneut hoch.',
    select_question: 'Antwortoptionen (eine richtige Antwort auswählen)',
    select_answer: 'Bitte wählen Sie eine Antwortoption aus!',
    check_option_one: 'Bitte geben Sie Option eins ein!',
    check_option_two: 'Bitte geben Sie Option zwei ein!',
    check_option_three: 'Bitte geben Sie Option drei ein!',
    check_option_four: 'Bitte geben Sie Option vier ein!',
    question_image: 'Fragebild',
    select_question_image: 'Bitte wählen Sie ein Fragebild aus!',
    ans_image: 'Antwortbild',
    upload_image_question: 'Antwortbild hochladen',
    select_image_answer: 'Bitte wählen Sie ein Antwortbild aus!',
    save_question: 'Frage speichern',
    do_you_leave: 'Sind Sie sicher, dass Sie die Seite verlassen möchten?',
    loose_all_progress: 'Sie werden Ihren gesamten Fortschritt verlieren.',
    welcome: 'Willkommen',
    team_knowledge_quest: 'Team Wissensaufgabe',
    your_score: 'Ihr Ergebnis',
    start_playing: 'Starten',
    share_sphere_complete: 'ShareSphere abgeschlossen',
    final_score: 'Endstand',
    play_next: 'Nächstes Spiel',
    you_donot_access_event: 'Sie haben keinen Zugang zu dieser Veranstaltung.',
    ai_content_generated: 'Inhalt für dieses Thema wurde generiert.',
    ai_popup_confirm_content: 'Inhaltsgenerierung bestätigen',
    limit_transcribe_text:
      'Verbleibende Transkriptionsminuten: <0>{{ transcribe_limit }}</0> Minuten <1/><2/> <3>. Um Ihr Transkriptionslimit zu ändern, kontaktieren Sie bitte </3> <4>admin@sharelook.com</4>.',
    ai_course_title_training: 'Führungskräftetrainings Kurse',
    ai_course_object: 'Führung in der digitalen Welt',

    create_ai_video: 'KI-Video erstellen',
    generate: 'GENERIEREN',
    video_generate_process:
      'Der Prozess zur Videogenerierung kann einige Zeit in Anspruch nehmen. Sie werden per E-Mail benachrichtigt, wenn er abgeschlossen ist.',
    stripe_account_connect:
      'Ihr Stripe-Konto ist inaktiv. Bitte verbinden oder aktivieren Sie es, um fortzufahren.',
    ai_creator: 'KI Ersteller',
    check_video_status: 'Überprüfung des Videogenerierungsstatus...',
    video_on_queue: 'Die Videogenerierung befindet sich in der Warteschlange.',
    video_fail_generation:
      'Die Videogenerierung ist fehlgeschlagen. Bitte versuchen Sie es erneut.',
    video_currently_generated: 'Dieses Video wird derzeit generiert.',
    checking_status: 'Status wird überprüft',
    check_status: 'Status überprüfen',
    browser_does_not_support_audio:
      'Ihr Browser unterstützt die Audio-Flagge nicht',
    existing_edit_scenario:
      'Möchten Sie das bestehende Szenario bearbeiten oder ein neues Szenario erstellen? Wenn Sie ein neues Szenario erstellen möchten, gehen alle alten Szenarien verloren.',
    generate_scenario: 'Szenario generieren',
    layout: 'Layout',
    confirm_change: 'Änderung bestätigen',
    generated_ai_creation_message:
      'Bitte beachten Sie, dass nur die Textelemente, die aus der KI-Erstellung generiert wurden, erhalten bleiben. Alle anderen Elemente werden von der Seite entfernt.',
    generate_story: 'Geschichte generieren',
    save_original_content: 'Ursprünglichen Inhalt speichern:',
    story_added_specified_position:
      'Die Position der Geschichte in der Lektion. Die Geschichte wird als Block an der angegebenen Position hinzugefügt.',
    position_of_story: 'Position der Geschichte',
    start_the_lesson: 'Beginn der Lektion',
    end_the_lesson: 'Ende der Lektion',
    add_story_pages: 'Geschichte zu Seiten hinzufügen',
    browser_does_not_support:
      'Ihr Browser unterstützt das Audio-Element nicht.',
    no_video_available: 'Keine Videos verfügbar.',
    no_podcast_available: 'Keine Podcasts verfügbar.',
    no_item_baskets: 'Keine Artikel im Korb.',
    ai_course_object: 'Führung in der digitalen Welt'
  },

  v2: {
    n_data: 'Keine Daten',
    change: 'Ändern',
    remove: 'Entfernen',
    auto_background: 'Automatischer Hintergrund',
    create_new_course_org:
      'Erstellen Sie einen neuen Kurs für Ihre Organisation.',
    create_new_course_marketplace:
      'Erstellen Sie einen neuen Kurs für den Marktplatz.',
    create_new_course_both:
      'Erstellen Sie einen neuen Kurs für Ihre Organisation und den Marktplatz.',
    ok: 'OK',
    page_bg: 'Seitenhintergrund',
    lesson_cover: 'Cover-Bild der Lektion',
    create_skill_journey: 'Erstellen Sie eine Fähigkeitsreise',
    Metamaze: 'Metamaze',
    events: 'Veranstaltungen',
    scroms: 'SCORMs',
    min_score_pass: 'Mindestpunktzahl zum Bestehen',
    no_scorms_available: 'Keine SCORMs verfügbar.',
    no_events_available: 'Keine Veranstaltungen verfügbar.',
    no_courses_available: 'Keine Kurse verfügbar.',
    no_courses_baskets: 'Keine Kurse im Korb.',
    no_mazes_baskets: 'Keine Labyrinthe im Korb.',
    no_scroms_baskets: 'Keine SCORMs im Korb.',
    no_events_baskets: 'Keine Veranstaltungen im Korb.',
    sorry: 'Entschuldigung!',
    option_1: 'Option 1',
    option_2: 'Option 2',
    enter_number_words: 'Geben Sie die maximale Anzahl von Wörtern ein.',
    summarize: 'Zusammenfassen',
    answer: 'Antwort',
    hold_tight_one_minute:
      'Bitte warten Sie einen Moment! Wir bereiten noch alles vor. Dies kann bis zu einer Minute dauern.',
    enter_question: 'Geben Sie Ihre Frage ein.',
    enter_relevant_documents: 'Geben Sie die Anzahl relevanter Dokumente ein.',
    please_input_relevant_documents:
      'Bitte geben Sie die Anzahl relevanter Dokumente ein!',
    please_wait_loading_results: 'Bitte warten Sie auf die Ergebnisse...',
    proceed_to_add_voice: 'Fahren Sie mit der Sprachauswahl fort',
    results: 'Ergebnisse',
    no_result_found_try_asking_questions:
      'Keine Ergebnisse gefunden. Versuchen Sie, mehr Fragen zu stellen.',

    eg: 'z.B.,',
    what_make_sales_manager: 'Was macht mich zu einem Vertriebsleiter?',
    please_select_lang: 'Bitte wählen Sie eine Sprache aus!',
    search: 'Suche',
    please_copy_desired_content:
      'Bitte kopieren Sie den gewünschten Inhalt von der linken Box in die rechte Box.',
    rating_req: 'Bewertung ist ein Pflichtfeld.',
    review_req: 'Rezension ist ein Pflichtfeld.',
    rating_review_req:
      'Es muss sowohl eine Bewertung als auch eine Rezension angegeben werden.',
    how_rate_this: 'Wie würden Sie dies bewerten?',
    amazing_above_expectations: 'Erstaunlich! Über den Erwartungen!',
    certificates: 'Zertifikate',
    you_no_certificates: 'Sie haben derzeit keine Zertifikate verfügbar.',
    please_add_review:
      'Bitte geben Sie eine Rezension zusammen mit der Bewertung ab. Vielen Dank!',
    post: 'Posten',
    rate: 'Bewerten',
    avg_rating: 'Durchschnittliche Bewertung',
    no_review_found: 'Keine Bewertung gefunden',
    are_sure_to_disale_zoom_integration:
      'Sind Sie sicher, dass Sie die Zoom-Integration deaktivieren möchten?',
    badges: 'Abzeichen',
    you_no_badge_available: 'Sie haben derzeit keine Abzeichen verfügbar.',
    download_certificate: 'Zertifikat herunterladen',
    loading: 'Laden...',
    no_file_attached: 'Keine Dateien angehängt',
    you_do_not_have_any_notes: 'Sie haben keine Notizen.',
    question: 'Frage',
    score: 'Punktzahl',
    alert_score_message: 'Person(en) haben ihre Punkte abgegeben.',
    alert_question_message: 'Bis jetzt wurden keine Fragen hinzugefügt.',
    button_submit: 'Einreichen',
    button_allScores: 'Alle Punkte wurden abgegeben.',
    record_wait: 'Die Aufnahme beginnt. Bitte warten...',
    buttonSubmit_warning:
      'Bitte geben Sie alle Punkte ein, bevor Sie sie einreichen.',
    upload_ar_assets: 'AR-Ressourcen hochladen',
    edit_ar_assets: 'AR-Ressourcen bearbeiten',
    edit_maze_assets: 'MetaMaze-Ressourcen bearbeiten',
    upload_assets: 'Ressourcen hochladen',
    upload_maze_assets: 'MetaMaze-Ressourcen hochladen',
    trigger_title: 'Trigger-Titel',

    model_title: 'Modellname',
    trigger_description: 'Auslöse-Beschreibung',
    model_description: 'Modell-Beschreibung',
    please_type_target_description: 'Bitte geben Sie die Zielbeschreibung ein.',
    please_type_model_description:
      'Bitte geben Sie die Modellbeschreibung ein.',
    trigger_image: 'Auslösebild*',
    model_image: 'Modellbild*',
    upload_trigger_image: 'Auslösebild hochladen',
    upload_model_image: 'Modellbild hochladen',
    drag_n_drop: 'Drag & Drop',
    file_size_should_not_exceed:
      '(Bilddatei (JPEG) darf 512 KB nicht überschreiten)',
    zip_size_should_not_exceed: '(ZIP-Datei darf 60 MB nicht überschreiten)',
    ar_content: 'AR-Inhalt*',
    model_content: '3D-Modell*',
    upload_3d_zip: 'ZIP / Video hochladen',
    upload_3d_maze_zip: 'ZIP hochladen',
    audio_content: 'Audio-Inhalt',
    _mp3: '(.mp3)',
    content_attached: 'ZIP angehängt.',
    video_attached: 'Video angehängt.',
    audio_attached: 'Audio angehängt.',
    enter_trigger_image_title: 'Titel des Auslösebildes eingeben.',
    enter_model_title: 'MetaMaze Modellname eingeben.',
    ar_preview_image: 'AR-Vorschau-Bild',
    upload_preview_image: 'Vorschau-Bild hochladen',
    upload_audio: 'Audio hochladen',
    creating___: 'Erstellen...',
    updating___: 'Aktualisieren...',
    create: 'Erstellen',
    update: 'Aktualisieren',
    ar_assets: '3D-Bild',
    _3d_files_mp4: '(Zipped .obj, .fbx oder .mp4 Video)',
    _3d_files_mp4_ar: '(Zipped .obj, .fbx)',
    maze_3d_files_mp4: '(Zipped .obj, .fbx)',
    _jpg_files: 'Unterstützt nur .jpg',
    persons_have_submitted_their_scores:
      'Person(en) haben ihre Punkte abgegeben.',
    no_questions_added_till_now: 'Bis jetzt wurden keine Fragen hinzugefügt.',
    submit: 'Einreichen',
    all_scores_submitted: 'Alle Punkte wurden abgegeben.',

    recording_is_starting: 'Die Aufnahme beginnt. Bitte warten...',
    please_input_scores_before_submitting:
      'Bitte geben Sie alle Punkte ein, bevor Sie sie einreichen.',
    end_for_all: 'Für alle beenden',
    full_screen: 'Vollbild',
    breakout_room: 'Breakout-Raum',
    send_invitations: 'Einladung(en) senden',
    add_participants: 'Teilnehmer hinzufügen',
    create_breakout_room: 'Breakout-Raum erstellen',
    connecting: 'Verbindung wird hergestellt...',
    currently_broadcaster_unavailable:
      'Der Broadcaster ist derzeit nicht verfügbar.',
    how_many_breakout_room_to_create:
      'Wie viele Breakout-Räume möchten Sie erstellen?',
    create_upto_three_breakout_rooms: '*Erstellen Sie bis zu 3 Breakout-Räume',
    allow: 'Erlauben',
    additional: 'Zusätzlich',
    attachment: 'Anhänge',
    no_description_available: 'Keine Beschreibung verfügbar.',
    people: 'PERSONEN',
    waiting: 'Warten',
    hand_raising_users: 'Benutzer die ihre Hand heben',
    invite: 'Einladen',
    create_room: 'Raum erstellen',
    create_live_event: 'Live-Event erstellen',
    support_upto_fivethousand_participants:
      'Unterstützt bis zu 5.000 Teilnehmer für das Event',
    support_upto_ten_participants:
      'Unterstützt bis zu 10 Live-Video-Teilnehmer',
    support_upto_twohundred_participants:
      'Unterstützt bis zu 200 Teilnehmer für das Event',
    supprt_upto_five_participants: 'Unterstützt bis zu 5 Live-Video-Teilnehmer',
    support_upto_twentyfive_participants:
      'Unterstützt bis zu 25 Live-Video-Teilnehmer für das Event',
    two_live_video_participants:
      'Es gibt 2 Live-Video-Teilnehmer für das Event (Host und Teilnehmer)',
    you_can_have_upto_five_examinees: 'Sie können maximal 5 Prüflinge haben.',
    you_can_have_upto_twentyfive_participants:
      'Sie können maximal 25 Teilnehmer haben.',
    you_can_have_upto_five_participants:
      'Sie können maximal 5 Teilnehmer haben.',
    you_can_have_upto_ten_participants:
      'Sie können maximal 10 Teilnehmer haben.',
    select_hosts_email: 'Host-E-Mail auswählen',
    send_invite_email: 'Einladungs-E-Mail senden',
    do_not_send_invite_email: 'Keine Einladungs-E-Mail senden',

    error: 'Fehler',
    max_twentyfive_users_allowed:
      'Maximal 25 Benutzer können ausgewählt werden',
    max_five_users_allowed: 'Maximal 5 Benutzer können ausgewählt werden',
    max_ten_users_allowed: 'Maximal 10 Benutzer können ausgewählt werden',
    no_user_found: 'Kein Benutzer gefunden',
    transcribe_to: 'Übertragen zu',
    integrate_zoom_to_start_meeting:
      'Integrieren Sie Zoom in Ihr Konto, um ein Zoom-Meeting zu starten.',
    zoom_is_not_integrated: 'Zoom ist nicht integriert.',
    delete_poll: 'Umfrage löschen',
    add_poll: 'Umfrage hinzufügen',
    live_now: 'JETZT LIVE!',
    average_rating: 'Durchschnittliche Bewertung',
    review: 'Bewertung',
    time_spent: 'Verbrachte Zeit',
    total: 'Gesamt',
    mins: 'Minuten',
    no_data: 'Für dieses Quiz liegen keine Daten vor.',
    total_score: 'Gesamtpunktzahl',
    pass_score: 'Mindestpunktzahl',
    point: 'Punkt',
    wrong: 'Falsch',
    response: 'Antwort',
    submitted: 'Abgeschickt',
    average: 'Durchschnitt',
    total_play: 'Gesamtspiel',
    view_graph: 'Diagramm anzeigen',
    time_spent_mins: 'Verbrachte Zeit (Minuten)',
    play: 'Abspielen',
    ideal_path: 'Idealer Pfad',
    user_incorrect_path: 'Falscher Benutzerpfad',
    ideal_path_taken: 'Vom Benutzer gewählter idealer Pfad',
    scenario: 'Szenario',
    option: 'Option',
    spend: 'Verbringen',
    average_spending_time: 'Durchschnittliche Verweildauer',
    activity_overview: 'Aktivitätsübersicht',
    live_event: 'Live-Event',
    live_assessment: 'Live-Bewertung',
    report_download: 'Bericht herunterladen',
    all_workshops: 'Alle Workshops',
    overview_average_activity: `ÜbersichtDurchschnittliche Aktivität`,
    overview_workshop_graph: 'Workshop-Übersichtsdiagramm',
    mazes: 'Labyrinthe',
    correct_feedback: 'Richtiges Feedback',
    incorrect_feedback: 'Falsches Feedback',
    checkin_checkout: 'Einchecken - Auschecken',
    minutes: 'Minuten',
    incorrect_path: 'Falscher Pfad',
    answer_not_available: 'Antwort nicht verfügbar',
    views: 'Ansichten',
    item: 'Element',
    questions: 'Fragen',
    total_points: 'Gesamtpunkte',
    total_attempts: 'Gesamtversuche',
    created_date: 'Erstellungsdatum',
    host_name: 'Host-Name',
    host_section: 'Host-Abschnitt',
    choose_products: 'Produkte auswählen',
    add_to_basket: 'Zum Warenkorb hinzufügen',
    basket: 'Warenkorb',
    times: 'mal',
    scorms: 'SCORMs',
    skill_journey_overview: 'Skill Journey Übersicht',
    how_to_use: 'Anwendung',
    live_broadcast_description:
      'WIR HABEN LIVE-VIDEO IN EINEN EINFACH ZU BENUTZENDEN KURSBUILDER INTEGRIERT',
    live_broadcast_subdescription:
      'Mit ShareLook’s patentiertem Live-Übertragungssystem können Sie Teilnehmer engagieren, Lernen automatisieren und den Wissensaustausch maximieren.',
    disclaimer: `Ich versichere, dass meine Fähigkeitsreise nicht:
- die Rechte Dritter verletzen wird
- keine Illegalität enthält, einschließlich diffamierender, obszöner, anstößiger, pornografischer, gewalttätiger, missbräuchlicher, beleidigender, bedrohlicher oder diskriminierender Inhalte, auch nicht durch Sternchen maskiert
- keine persönlichen Angriffe auf andere Personen enthält
- keine Personen, öffentlichen Personen oder Prominenten nachahmt
- keine Aufzeichnungen oder persönlichen Informationen über Einzelpersonen ohne deren Zustimmung enthält und nicht gegen Datenschutzbestimmungen verstößt, die in jeglichen Teilen der Welt gelten
- keine kommerzielle Werbung oder Form von "Spam" enthält`,
    price: 'Preis',
    video_link: 'Video-Link',
    category: 'Kategorie',

    objectives: 'Ziele',
    marketplace_description: 'Marktplatzbeschreibung',

    live_discussion: 'LIVE DISKUSSION',
    poll: 'UMFRAGE',
    hide_grid: 'Raster ausblenden',
    show_grid: 'Raster anzeigen',
    stop_screen_share: 'Bildschirmfreigabe beenden',
    screen_share: 'Bildschirm teilen',
    mute: 'Stummschalten',
    unmute: 'Stummschaltung aufheben',
    raise_hand: 'Hand heben',
    waiting__: 'Warten...',
    audio_active: 'Audio aktiv'
  },
  general: {
    save: 'speichern',
    autosaved: 'automatisch gespeichert',
    back_to: 'zurück zu',
    by: 'von',
    chat: 'chat',
    completed: 'Abgeschlossen',
    canceled: 'Abgebrochen',
    component: 'Komponente',
    confirm: 'Bestätigen',
    course: 'Kurs',
    date: 'Datum',
    details: 'Details',
    empty: 'Keine Daten',
    enrolled: 'Eingeschrieben',
    free: 'kostenlos',
    graph: 'Diagramm',
    loading: 'Laden...',
    lesson: 'Lektion',
    lessons: 'Unterricht',
    live_assessment: 'Live-Bewertung',
    live_event: 'Live-Event',
    no: 'Nein',
    of: 'von',
    or: 'oder',
    organization: 'Organisation',
    page: 'Seite',
    pages: 'Seiten',
    personal: 'persönlich',
    poll: 'Umfrage',
    rating: 'Bewertung',
    reset: 'Zurücksetzen',
    signin: 'Anmelden',
    signup: 'Registrieren',
    soon: 'Bald',
    type: 'Typ',
    uploading: 'Hochladen...',
    yes: 'Ja',
    something_went_wrong: 'Irgendetwas ist schief gelaufen!',
    view_all: 'Alle anzeigen',
    upload_successful: 'Upload erfolgreich',
    upload_successful_message:
      'Ihre Excel-Dokumente wurden erfolgreich hochgeladen und werden derzeit verarbeitet. Dies kann einige Zeit in Anspruch nehmen.',
    check_email_upload_successful:
      'Bitte <0>überprüfen Sie Ihre E-mail</0> auf den Bericht <1>report</1> der hochgeladenen Benutzerliste.',
    is_private: 'privat'
  },

  account: {
    title: 'Konto',
    subtitle: 'Verwalten Sie Ihre Einstellungen und Kontoinformationen.',
    saved: 'Konto erfolgreich geändert'
  },

  assessments: {
    title: 'Bewertungen',
    subtitle: 'Erstellen einer Bewertung',
    finished: 'Die Bewertung ist abgeschlossen',
    countdown: 'Die Live-Bewertung wird in ',
    create: 'Erstellen von Bewertungen',
    list_title: 'Ihre Bewertungen',
    delete_warning: 'Möchten Sie dieses Element wirklich löschen?',
    details_title: 'Hinzufügen von Bewertungsdetails',
    details_subtitle:
      'Benennen Sie Ihre Bewertung und teilen Sie mit, was Ihre Leser daraus lernen sollten',
    disclaimer: `Ich versichere, dass in den Aufzeichnungen keine:
    - Rechte dritter Parteien verletzt werden
    - gesetzeswidrigen, rufschädigenden, obszönen, unsittlichen, anzüglichen, pornografischen, gewalttätigen, beleidigenden, unverschämten, drohenden oder diskriminierenden Inhalt                                    enthalten, dieser wird auch nicht mit Sternchen verborgen
    - persönliche verbale Angriffe enthalten
    - Imitationenvon anderen Teilnehmern, öffentlicher Personen oder Berühmtheiten enthalten\
    - Aufnahmenvon persönlichen Informationen einer Person ohne dessen Zustimmung enthalten und dass keine Datenschutzverordnungen in irgendeinem Land der Welt verletzt werden\
    -  gewerbliche Werbung oder sonstige Arten von “Spam” enthalten`,
    assessment_details: 'Einzelheiten der Bewertung',
    assessment_settings: 'Assessment Einstellungen'
  },

  buildersettings: {
    title: 'Veröffentlichungseinstellungen',
    subtitle:
      'Fügen Sie zu den Einstellungen hinzu, wer Ihr Event sehen und mit Ihnen interagieren kann'
  },

  buttons: {
    market_place: 'Market Place',
    back_to_normal: 'Back To Normal',
    add: 'Hinzufügen',
    add_page: 'Seite hinzufügen',
    add_lesson: 'Lenktion hinzufügen',
    add_answer: 'Neue Antwort hinzufügen',
    add_group: 'Gruppe hinzufügen',
    add_organization: 'Organisation hinzufügen',
    add_question: 'Frage hinzufügen',
    add_quiz: 'Quiz hinzufügen',
    add_users: 'Benutzer hinzufügen',
    back: 'Zurück',
    back_to_course: 'Zurück zur Kursübersicht',
    back_to_lesson: 'Zurück zur Lektionsübersicht',
    back_to_media: 'Zurück zur Medienbibliothek',
    back_to_details: 'Zurück zu Details',
    cancel: 'Abbrechen',
    clear: 'bereinigen',
    close: 'Schließen',
    clone: 'Duplizieren',
    close_lesson: 'Lektion beenden',
    clone_course: 'Kurs duplizieren',
    confirm: 'Bestätigen',
    create: 'Erstellen',
    download: 'Herunterladen',
    download_mass_user_tamplate: 'Vorlage herunterladen',
    add_mass_users: 'Hinzufügen von Massenbenutzern',
    upload_excel_list: 'Excel-Liste hochladen',
    delete: 'Medien löschen',
    edit: 'Bearbeiten',
    edit_course: 'Kurs bearbeiten',
    enroll: 'Registrieren',
    finish: 'Beenden',
    upload_image: 'Bild hochladen',
    upload_image_video: 'Bild oder Video hochladen',
    select_media_library: 'Medienbibliothek auswählen',
    make_public: 'veröffentlichen',
    make_private: 'privat machen',
    make_org: 'Organisation erstellen',
    media: 'Mediathek',
    my_theme: 'Mein Thema',
    next: 'nächste',
    next_lesson: 'Nächste Lektion',
    next_step: 'Nächster Schritt',
    previous_step: 'Vorheriger Schritt',
    publish: 'Veröffentlichen',
    publish_assessment: 'Veröffentlichen Sie Ihre Bewertung',
    publish_course: 'Veröffentlichen Sie Ihren Kurs',
    publish_event: 'Veröffentlichen Sie Ihr Event',
    reset: 'Zurücksetzen',
    reset_elements: 'Elemente zurücksetzen',
    save: 'Speichern',
    creating: 'Erstellen...',
    updating: 'Aktualisieren...',
    saved: 'Gespeichert',
    save_for_later: 'Für später speichern',
    save_settings: 'Einstellungen speichern',
    select_files: 'Dateien auswählen',
    select_theme: 'Wählen Sie ein Thema',
    start_lesson: 'Lektion starten',
    update: 'Update',
    upload: 'Hochladen',
    upload_audio: 'Audio hochladen',
    upload_photo: 'Profilbild hochladen',
    upload_video: 'Video hochladen',
    enrolled: 'registriert',
    unenroll: 'nicht registrieren',
    from_library: 'Mediathek',
    remove: 'Entfernen',
    removed: 'Entfernt',
    start_broadcast: 'Übertragung starten',
    end_broadcast: 'Übertragung beenden',
    camera: 'Kamera',
    screen: 'Bildschirm',
    screen_with_camera: 'Bildschirm mit Kamera',
    insert: 'Einfügen',

    inserting: 'Einfügen'
  },

  card: {
    quick_add: 'Schnelles Hinzufügen',
    add: 'Hinzufügen',
    clone: 'Duplizieren',
    completed: 'Lektion abgeschlossen'
  },

  course: {
    upcoming_course: 'geplanter Kurs',
    unPublishEvent: 'Event nicht veröffentlichen',
    unPublishAssessment: 'Bewertung nicht veröffentlichen',
    pastCourse: 'Trainingskurs',
    upcoming: 'geplantes Live-Event',
    pastEvent: 'Vergangenes Live-Event',
    pastAssessment: 'Vergangene Live-Bewertung',
    upcomingAssessment: 'geplante Live-Bewertung',
    created: 'Erstelltes Datum:',
    assignees: 'Beauftragte',
    organisations:
      ' {{ Organisationen }} Organisationen, <2> {{ Benutzer }} Benutzer</2>',
    event_date: 'Live-Event Datum <1> {{ Datum }} </1>',
    delete_warning: 'Sind Sie sich sicher, dass Sie das löschen wollen?',
    clonning_no_lesson:
      'Bitte fügen Sie mindestens eine Lektion hinzu, um diesen Kurs zu duplizieren..',
    clone_warning: 'Sind Sie sicher, dass Sie diesen Kurs duplizieren wollen?',
    clone_success: 'wird dupliziert',
    disclaimer: `Ich versichere, dass meine Kurse nicht:
    - die Rechte dritter Parteien verletzen
    - gesetzeswidrigen, rufschädigenden, obszönen, unsittlichen, anzüglichen, pornografischen, gewalttätigen, beleidigenden, unverschämten, drohenden oder diskriminierenden Inhalt                                    enthalten, dieser wird auch nicht mit Sternchen verborgen
    - persönliche verbale Angriffe enthalten
    -Imitationen von anderen Teilnehmern, öffentlicher Personen oder Berühmtheiten enthalten
    -Aufnahmen von persönlichen Informationen einer Person ohne dessen Zustimmung enthalten       
     und dass keine Datenschutzverordnungen in irgendeinem Land der Welt verletzt werden
    -gewerbliche Werbung oder sonstige Arten von “Spam” enthalten`
  },

  coursecreate: {
    dropzone_text: 'Einfügen von Medien',
    sidebar_text: 'Wählen Sie ein Thema <1/> für Ihre <3/> Lektionen',
    delete_warning: 'Möchten Sie diese Lektion wirklich löschen?'
  },

  coursepage: {
    quizview_loading: 'Überprüfung der Antworten. Bitte warten...',
    quizview_answer: 'Ihre Antwort'
  },

  coursequiz: {
    title: 'Quiz in allen Lektionen'
  },

  dashboard: {
    tooltip_title: 'Dieses Video nicht mehr anzeigen',
    page_title: 'Die Statistik Ihrer Kurse',
    page_subtitle: 'Status der von Ihnen erstellten Kurse',
    profile_add_info:
      'Fügen Sie Ihre <1>{{unvollständigertext}}</1> , um Ihr Profil zu vervollständigen.',
    profile_build:
      'Erstellen Sie Ihr Profil <1>Hier</1>, teilen Sie uns Ihre Interessen und Fähigkeiten mit.',
    profile_title: 'Profilstärke',
    more_details: 'Weitere Details'
  },

  errors: {
    answer: 'Bitte geben Sie Antwort ein',
    answer_correct: 'Wählen Sie die richtige Antwort',
    code: 'Bitte geben Sie Ihren Code ein',
    criterion: 'Bitte geben Sie das Kriterium an',
    duration: 'Dauer ist erforderlich',
    element: 'Bitte geben Sie ein Element ein',
    email: 'Bitte geben Sie Ihre E-Mail-Adresse ein',
    email_invalid: 'Die eingegebene E-Mail-Adresse ist ungültig',
    feedback_correct: 'Bitte geben Sie positives Feedback',
    feedback_incorrect: 'Bitte geben Sie konstruktives negatives Feedback',
    host: 'Bitte geben Sie einen Leiter des Events an',
    host_assessment: 'Bitte geben Sie einen Leiter der Bewertung an',
    image: 'Bitte laden Sie ein Bild hoch',
    location: 'Bitte geben Sie Ihren Standort ein',
    live_location: 'Bitte wählen Sie einen Sendeort aus',
    limit: 'Bitte geben Sie Live-Streaming-Minuten ein',
    min_3char: 'Mindestens 3 Zeichen',
    number: 'bitte eine positive Zahl eingeben.',
    host_email_require: `Die E-Mails der Leiters sind erforderlich.`,
    attendee_email_require: `Die E-Mails der Teilnehmer sind erforderlich.`,
    name: 'Bitte geben Sie Ihren Namen ein',
    not_found_course: 'Kurs konnte nicht gefunden werden',
    not_found_event: 'Event konnte nicht gefunden werden',
    not_found_assessment: 'Bewertung nicht gefunden',
    password: 'Bitte geben Sie Ihr Passwort ein',
    password_digit: 'Mindestens eine Ziffer',
    password_short: 'Passwort ist zu kurz, mindestens 10 Zeichen',
    password_special: 'Mindestens ein Sonderzeichen',
    password_uppercase: 'Mindestens ein Großbuchstaben',
    password_lowercase: 'Mindestens ein Kleinbuchstaben',
    phone: 'Bitte geben Sie Ihre Telefonnummer ein',
    phone_invalid: 'Ungültige Telefonnummer',
    position: 'Bitte geben Sie Ihre Position ein',
    question: 'Bitte geben Sie die Frage ein',
    start_date: 'Startdatum & Uhrzeit sind erforderlich',
    tag: 'Bitte wählen Sie eine verlinkung aus',
    topics: 'Wählen Sie bitte mindestens ein Thema aus.',
    live_topics: 'Bitte fügen Sie Themen hinzu',
    title: 'Bitte geben Sie den Titel ein',
    title_assessment: 'Bitte geben Sie einen Bewertungstitel ein',
    title_course: 'Bitte geben Sie den Kurstitel ein',
    title_empty: 'Es muss einen Titel geben',
    title_event: 'Bitte geben Sie den Titel des Events ein',
    title_lesson: 'Bitte geben Sie den Titel der Lektion ein',
    title_quiz: 'Bitte geben Sie den Quiznamen ein',
    username: 'Bitte geben Sie Ihren Benutzernamen ein',
    upload_error: 'Fehler - Datei ist nicht kompatibel!',
    upload_more_than_10:
      'Sie können nur maximal 10 Dateien auf einmal hochladen.',
    topics_max: 'Bitte fügen Sie maximal 3 Themen hinzu.',
    upload_excel:
      'Bitte stellen Sie sicher, dass Sie die richtige Datei hochgeladen haben.',
    title_excel: 'Fehler beim Hochladen der Vorlage:',
    file_size_exceed: 'Dateigröße überschritten',
    subtitle_excel:
      'Stellen Sie sicher, dass Sie die bereitgestellte Vorlage verwenden und die Spalten korrekt ausgefüllt sind.',
    select_broadcast_option:
      'Wählen Sie eine Option zum Übertragen dieses Events aus.',
    multi_hosts_number_error:
      'Nein. die Anzahl der Moderatoren darf nicht die vorher für das Event angegeben Anzahl überschreiten.',
    conference_people_number_error: `Nein. Die Teilnehmerzahl die angegeben wurde, darf nicht überschritten werden`,
    select_max_host: 'Wählen Sie mindestens 1 Moderator für das Event aus.',
    select_max_conference:
      'Wählen Sie mindestens 1 Teilnehmer für das Event aus.',
    stream_stop_error: 'Streaming wurde unerwartet gestoppt!',
    unsecure_context:
      'Schwerwiegender Fehler: Der Browser kann aufgrund eines unsicheren Kontexts nicht auf Kamera und Mikrofon zugreifen. Bitte installieren Sie SSL und Zugriff über https',
    ws_not_supported:
      'Schwerwiegender Fehler: WebSocket wird in diesem Browser nicht unterstützt',
    no_org: 'Wählen Sie mindestens 1 Organisation zum Fortfahren aus',
    no_org_group:
      'Wählen Sie mindestens 1 Organisationsgruppe aus, um fortzufahren',
    waiting_to_join: 'Warten auf den Beitritt'
  },

  events: {
    title: 'Live-Eventdetails hinzufügen',
    change_host: 'Leiter ändern',
    finished: 'Das Event ist beendet',
    countdown: 'Live-Event wird übertragen in ',
    subtitle:
      'Benennen Sie Ihr Live-Event und sagen Sie Ihrem Publikum, was sie davon erwarten sollten.',
    poll_disabled: 'Umfragen sind in diesem Fall nicht verfügbar',
    disclaimer: `Ich versichere, dass meine Kurse nicht:
    - die Rechte dritter Parteien verletzen
     - gesetzeswidrigen, rufschädigenden, obszönen, unsittlichen, anzüglichen, pornografischen, gewalttätigen, beleidigenden, unverschämten, drohenden oder diskriminierenden Inhalt                                    enthalten, dieser wird auch nicht mit Sternchen verborgen
      - persönliche verbale Angriffe enthalten
      -Imitationen von anderen Teilnehmern, öffentlicher Personen oder Berühmtheiten enthalten
      -Aufnahmen von persönlichen Informationen einer Person ohne dessen Zustimmung enthalten
     und dass keine Datenschutzverordnungen in irgendeinem Land der Welt verletzt werden
     -gewerbliche Werbung oder sonstige Arten von “Spam” enthalten'
    },
    '`,
    prompt_message:
      'Wenn Sie diese Seite verlassen, wird das Ereignis beendet. Wollen Sie wirklich die Seite verlassen?',
    duration_exceed_message:
      'Ihre Sendezeit wird überschritten, die App wird die Übertragung beenden.',
    before_schedule_message:
      'Sie sind dabei, die Übertragung vor der geplanten Startzeit zu starten. Bitte beachten Sie, dass das Beenden der Übertragung das Event beendet.',
    browser_unsupportive_message:
      'Ihr Browser unterstützt kein Screenshare. Sie können unterstützende Browser in diesem Link sehen',
    cancel_switch_host_message:
      'Leiter ist nicht verfügbar. Das event wird von Ihrem Ende an fortgesetzt.',
    coaching_prompt_message:
      'Sie können diese Seite nicht verlassen, bis das Ereignis abgeschlossen ist.',
    event_modal_warn: 'Möchten Sie dieses Ereignis verlassen?',
    confirm_message: 'Das Event endet, wenn Sie auf OK klicken.',
    screen_share_denied: 'Sie haben die Freigabe Ihres Bildschirms verweigert',
    media_not_found:
      'Kamera oder Mikrofon wurden nicht gefunden oder sind in Ihrem Gerät nicht erlaubt',
    media_not_access:
      'Kamera oder Mikrofon wird von einem anderen Prozess verwendet, der die Geräte nicht lesen lässt',
    constraint_error:
      'Es wurde kein Gerät gefunden, das ihren Video- und Audioeinschränkungen entspricht. Sie können Video- und Audioeinschränkungen ändern',
    media_access_denied: 'Sie dürfen nicht auf Kamera und Mikrofon zugreifen.'
  },

  filters: {
    days7: 'Letzten 7 Tage',
    days15: 'Letzten 15 Tage',
    days30: 'Letzten 30 Tage',
    days365: 'Letzten 365 Tage',
    date: 'Erstelltes Datum',
    relevance: 'Relevanz',
    popularity: 'Popularität',
    name: 'Name',
    group: 'Gruppe',
    Newest_to_Oldest: 'Neueste bis Älteste',
    Oldest_to_Newest: 'Älteste bis neueste',
    A_Z: 'A bis Z',
    Z_A: 'Z bis A'
  },

  helpers: {
    password:
      'Um die Sicherheit Ihres Kontos zu gewährleisten, benötigen wir mindestens eine Kleinbuchstaben und eine Nummer in Ihrem Passwort. Sie müssen auch eine Mindestlänge von 10 Zeichen haben.',
    phone: 'Bitte geben Sie Ihre Telefon-Ländervorwahl an, z. B.: +65',
    username:
      'Ihr Benutzername wird als öffentliche Information behandelt und bei Bedarf mit anderen Benutzern geteilt. Daher empfehlen wir Ihnen, keine personenbezogenen Daten in Ihrem Benutzernamen zu verwenden.',
    public_description_normal:
      'Ihre <0><0/></0> wurde gespeichert und gilt standardmäßig als Privat. Dies bedeutet, dass nur Sie diesen Inhalt sehen können. Um diesen Inhalt zu veröffentlichen, können Sie eine der folgenden Optionen auswählen. Die Veröffentlichung Ihres Kurses unter "Öffentlich" bedeutet, dass er allen Benutzern auf der Plattform zur Verfügung steht. Mit der Option Spezifische Benutzer können Sie Ihren Kurs mit bestimmten Benutzern teilen, indem Sie die E-Mail-Adresse verwenden, die mit ihrem Sharelook-Konto verknüpft ist.',
    public_description_admin:
      'Dieser <0><0/></0> wurde gespeichert und gilt standardmäßig als Privat. Dies bedeutet, dass nur Sie (und der Inhaltsersteller) diesen Inhalt sehen können. Um diesen Inhalt zu veröffentlichen, können Sie eine der folgenden Optionen auswählen. Das Veröffentlichen des Kurses unter "Öffentlich" bedeutet, dass er für alle Benutzer auf der Plattform verfügbar ist , auch für Benutzer außerhalb Ihrer Organisation. Die Veröffentlichung in der Organisation bedeutet, dass alle Benutzer innerhalb der Organisation sie sehen können. Sie können es auch in 1 oder mehr Gruppen veröffentlichen, d. h., nur die Benutzer innerhalb dieser Gruppen können sie sehen.  Schließlich haben Sie die Möglichkeit, es für bestimmte Benutzer innerhalb und außerhalb Ihrer Organisation unter Verwendung ihrer E-Mail-Adresse des Sharelook-Kontos zu veröffentlichen.'
  },

  labels: {
    add: 'Hinzufügen',
    portrait: 'Crop Portrait',
    landscape: 'Crop Landscape',
    answer_type: 'Antworttyp',
    answers: 'Antworten',
    back_to_quiz: 'Zurück zur Quizliste',
    back_to_question: 'Zurück zur Fragenliste',
    chat: 'Chat',
    chat_available: 'Chat ist in diesem Fall nicht verfügbar',
    chat_report: 'Chat-Meldung',
    choice_single: 'Einzelwahl',
    choice_multiple: 'Mehrfach wählen',
    choice_free: '"Freie" Auswahl',
    choice_sorting: '"Sortieren" Auswahl',
    choice_matrix: '"Matrix Sortierung" Auswahl',
    clone_lesson_permission: 'Berechtugung zum Duplizieren',
    code: 'Code',
    correct: 'Richtig',
    created: 'Erstelltes Datum',
    clone_modal: 'Kurs duplizieren',
    cloning_course: 'Kurs wird dupliziert...',
    cloning: 'duplizieren...',
    cloning_course_desc:
      'Dieser Prozess wird einige Zeit dauern. Bitte haben Sie Geduld',
    cloning_success: 'Kurs "<0/>" wurde erfolgreich dupliziert!',
    description: 'Beschreibung',
    disclaimer: 'Haftungsausschluss',
    drop_file: 'Datei hier ablegen...',
    duration: 'Dauer',
    edit: 'Bearbeiten',
    email: 'E-mail-Adresse',
    event: 'Event',
    event_all: 'Alle seine Events',
    event_date: 'Live Event Lokales Datum/Uhrzeit',
    event_this: 'Dieses Event',
    expire: 'Verfällt am',
    expiry_date: 'Ablaufdatum',
    forgot: 'Passwort vergessen?',
    group: 'Gruppe',
    groups_list: 'Liste der Gruppen',
    host: 'Leiter',
    id_annotation: 'Anmerkungs-ID',
    id_quiz: 'Quiz-ID',
    incorrect: 'Falsch',
    interests: 'Interessen',
    invited: 'Eingeladen',
    live_chat: 'Live-Diskussion',
    live_stream_limit: 'Live-Übertragungs-Minuten',
    contact_text:
      'Um Ihr monatliches Live-Übertragungs-Limit zu ändern, wenden Sie sich bitte an uns',
    contact_admin:
      '<0>Wenn Sie Ihr monatliches Live-Übertragungs-Limit ändern möchten, wenden Sie sich bitte an</0> <1>admin@sharelook.com</1>',
    limit_stream_text:
      'Verbleibende Live-Übertragungs-Minuten:<0>{{ limit }}</0> Minuten <1/><2/> <3>Um Ihr monatliches Live-Übertragungs-Limit zu ändern, wenden Sie sich bitte an</3> <4>admin@sharelook.com</4>',
    location: 'Standort',
    lessons: 'Lektionen',
    location_broadcast: 'Hostserver-Standort',
    location_broadcast_subtitle:
      'Hinzufügen eines Standorts, der dem Standort des Broadcast-Hosts am nächsten liegt, um die audiovisuelle Verzögerung zu verringern',
    media_manager: 'Medienmanager',
    minutes: 'Minuten',
    name: 'Name',
    online_quiz: 'Online Quiz',
    organiser: 'Autor',
    organization: 'Organisation',
    overall_emotion: 'Gesamterfahrung:',
    organizations_list: 'Liste von Organisationen',
    password: 'Passwort',
    participants: 'Teilnehmer',
    picture: 'Bild (16:9)',
    phone: 'Telefon',
    photo: 'Bild',
    poll: 'Umfrage',
    position: 'Position',
    processing: 'Verarbeitung',
    privacy_settings: 'Datenschutzeinstellungen',
    question: 'Frage',
    quiz_create: 'Neues Quiz erstellen',
    quiz_name: 'Quiz Name',
    quiz_update: 'Quiz aktualisieren',
    select_organization: 'Auswählen einer Organisation',
    settings: 'Einstellungen',
    show: 'Zeigen',
    show_graph: 'Ergebnis im Diagramm anzeigen',
    signin: 'Anmelden',
    signup: 'Registrieren',
    sort_by: 'Sortieren nach',
    start_date: 'Startdatum',
    tags: 'Verlinkungen',
    terms: 'Ich stimme diesen <1>Geschäftsbedingungen</1> zu.',
    title: 'Titel',
    topics: 'Themen',
    topic: 'Thema',
    total_comments: 'Alle Kommentare',
    username: 'Benutzername',
    users_list: 'Liste der Benutzer',
    users_emails: 'Einfügen bestimmter Benutzer-E-Mail-Adressen',
    description_specifiction_email:
      'Bitte geben Sie 1 E-Mail-Adresse pro Zeile ein, um es mit mehreren bestimmten Benutzern zu teilen.',
    message_specific_email:
      'Bitte geben Sie die E-mail-Adresse eines bestimmten Nutzers ein',
    viewers: 'Zuschauer',
    edit_course: 'Bearbeiten',
    edit_assessment: 'Bearbeiten',
    edit_event: 'Bearbeiten',
    delete_course: 'Löschen',
    delete_event: 'Löschen',
    delete_assessment: 'Löschen',
    view_course: 'Ansehen',
    view_event: 'Ansehen',
    view_assessment: 'Ansehen',
    date_event: 'Live-Event-Datum',
    date_assessment: 'Live-Bewertungs-Datum',
    clone_of: 'Duplizieren',
    clone_course:
      'Erstellen Sie ein Duplikat dieses Kurses, den Sie unabhängig vom Original ändern können, einschließlich aller Lektionen und Seiten.',
    make: 'Machen',
    available_to: 'für jeden ShareLook-Benutzer verfügbar',
    uploaded_by: 'Hochgeladen von: ',
    course: 'Kurs',
    assessment: 'Bewertung',
    user_not_exist:
      'Benutzer existiert nicht, möchten Sie eine Einladung senden?',
    check_user: 'Vermerken',
    user_not_exist_in_org:
      'Der Benutzer existiert in dieser Organisation nicht, möchten Sie eine Einladung senden?',
    title_modal_stream: 'Ändern des Livestream-Limits der Organisation',
    des_warning_stream: 'Sie sind dabei etwas zu ändern',
    monthly_stream: 'monatliches Live-Streaming-Limit:',
    min_per_month: 'min/Monat',
    broadcast_label: 'Ich möchte:',
    hosts_emails: `Einfügen bestimmter E-Mails des Leiters`,
    attendee_emails: `Einfügen bestimmter Teilnehmer-E-Mails`,
    description_host_specifiction_email:
      'Bitte geben Sie 1 E-Mail-Adresse pro Zeile ein.',
    presenter_label: 'Moderator',
    presenter_label_conference: 'keine Abwesenden',
    event_privacy: 'Konferenzmodus'
  },

  broadcastOptions: {
    stream_from_phone: 'Live-Übertragung mit meinem Handy',
    other_webrtc: 'Webinar von meinem PC aus',
    multihost: 'Multicast-Webinar von meinem PC aus',
    peer2peer: 'Trainings-Sitzung',
    conference: 'Live-Konferenz'
  },

  lessoncreate: {
    title: 'Hinzufügen von Lektionsdetails',
    subtitle:
      'Benennen Sie Ihre Lektion und sagen Sie Ihrem Publikum, was Sie davon lernen können.',
    input_label_title: 'Titel der Lektion',
    input_label_topics: 'Thema der Lektion',
    input_label_description: 'Lektionsbeschreibung',
    delete_warning: 'Möchten Sie diese Seite wirklich löschen?'
  },

  lessonoverview: {
    title: 'Hinzufügen von Lektion',
    subtitle:
      'Hinzufügen vorhandener Lektionen oder Erstellen einer neuen Lektion',
    new_lesson: 'Erstellen einer neuen Lektion',
    new_lesson_sub: 'Erstellen Sie eine brandneue Lektion von Grund auf neu!',
    existing_lesson: 'Vorhandene Lektion auswählen',
    existing_lesson_sub:
      'Wenn Sie eine Verknüpfung zu einer vorhandenen Lektion herstellen, wirken sich die an dieser Lektion vorgenommenen Änderungen auch auf diese Lektion in anderen Kursen aus.',
    clone_lesson: 'Vorhandene Lektion duplizieren',
    clone_lesson_sub:
      'Machen Sie eine einzelne Kopie der Lektion in diesem Kurs, Änderungen an dieser Lektion wirken sich nicht auf die ursprüngliche Lektion aus.',
    clone_lesson_modal_sub:
      'Klicken Sie auf die Schaltfläche "Duplizieren" unter der Lektion, die Sie Ihrem Kurs hinzufügen möchten.'
  },

  management: {
    groups_title: 'Verwalten von Gruppen',
    delete_warning: 'Bist Sie sicher?',
    organizations_title: 'Meine Organisationen',
    organizations_subtitle: 'Hinzufügen von Organisationen zu Ihrer Liste',
    users_title: 'Verwalten von Benutzern',
    users_position: 'Senior Account Manager',
    user_not_found: 'Benutzer nicht gefunden',
    select_org:
      'Suchen und Auswählen einer Organisation zum Verwalten von Benutzern'
  },

  audio: {
    modal_title: 'Einfügen von einer Audio',
    list_view: 'Listenansicht',
    create_audio_modal_title: 'Erstellen von einer Audio',
    edit_audio_modal_title: 'Audio bearbeiten',
    public_audio_not_found: 'Audio wurde nicht gefunden.',
    private_audio_not_found: 'Sie haben noch keine Audio hochgeladen',
    are_you_sure_to_delete: 'Möchten Sie das wirklich löschen?',
    type_something: 'Geben Sie etwas ein',
    select_voice: 'Auswählen',
    file_name: 'Dateiname',
    description: 'Beschreibung',
    topic: 'Thema',
    key_words_tag: 'Schlagworte Tag',
    mp3_audio: 'MP3 Audio',
    created: 'Erstellt',
    duration: 'Dauer',
    close: 'Schließen'
  },

  image: {
    modal_title: 'Bild einfügen',
    image: 'Bild',
    pixel: 'Pixel',
    dimension: 'Dimension',
    public_image_not_found: 'Bild wurde nicht gefunden.',
    private_image_not_found: 'Sie haben noch kein Bild hochgeladen'
  },

  annotation: {
    modal_title: 'Anmerkung'
  },

  text: {
    modal_title: 'Texteditor'
  },

  video: {
    modal_title: 'Einfügen von Videos',
    image: 'Video',
    mp4_video: 'MP4 Video',
    public_video_not_found: 'Video wurde nicht gefunden.',
    private_video_not_found: 'Sie haben noch kein Video hochgeladen'
  },

  media: {
    title: 'Mediathek',
    subtitle: 'Bibliothek Ihrer Dateien',
    modal_title: 'Hochladen von Medien',
    delete_warning: 'Bestätigen des löschens',
    delete_message:
      'Gelöschte Medien werden weiterhin auf den Seiten angezeigt, auf denen sie verwendet wurden',
    dropzone_text: 'oder Drag and Drop',
    delete_success: 'Medien wurden erfolgreich gelöscht',
    upload_file: 'Datei hochladen',
    drag_drop: 'oder Drag and Drop',
    import_file: 'Importieren Sie Dateien von Ihrem Computer',
    no_media: 'Sie haben noch keine Medien hochgeladen',
    media_type: 'Medientyp',
    video: 'Video',
    image: 'Bild',
    audio: 'Audio',
    gif: 'GIF',
    transcribed_text: 'Transcribed Text',
    media_not_found: 'Medien wurden nicht gefunden.',
    of: 'von',
    disclaimer: `Ich versichere, dass meine Medien nicht:
    - die Rechte dritter Parteien verletzen
    - gesetzeswidrigen, rufschädigenden, obszönen, unsittlichen, anzüglichen, pornografischen, gewalttätigen, beleidigenden, unverschämten, drohenden oder diskriminierenden Inhalt                                    enthalten, dieser wird auch nicht mit Sternchen verborgen
    - persönliche verbale Angriffe enthalten
    -Imitationen von anderen Teilnehmern, öffentlicher Personen oder Berühmtheiten enthalten
    -Aufnahmen von persönlichen Informationen einer Person ohne dessen Zustimmung enthalten       
     und dass keine Datenschutzverordnungen in irgendeinem Land der Welt verletzt werden
    -gewerbliche Werbung oder sonstige Arten von “Spam” enthalten`,
    my_media: 'Meine Medien',
    public_library: 'Öffentliche Bibliothek',
    organization_library: 'Organisationsbibliothek',
    upload_from_computer: 'Hochladen vom Computer',
    create: 'Anlegen',
    all: 'Alle',
    male: 'Männlich',
    female: 'Weiblich',
    processing_audio: 'Verarbeitung von Audio',
    create_annotation: 'Annotation erstellen',
    popup_over_lesson_text:
      'Die Anmerkung wird nach dem Klicken über der Lektionsseite eingeblendet',
    edit_detail: 'Detail bearbeiten',
    delete: 'Löschen',
    go_create_audio: 'Gehen Sie Audio erstellen',
    show_more_details: 'mehr Details anzeigen',
    show_less_details: 'weniger Details anzeigen',
    drop_files: 'Dateien ablegen und hochladen',
    or: 'Oder',
    import_files: 'Dateien vom Computer importieren'
  },

  navigation: {
    account: 'Konto',
    assessments: 'Bewertungen',
    dashboard: 'Dashboard',
    groups: 'Gruppenmanagement',
    logout: 'Ausloggen',
    organisations: 'Organisationen',
    organizations_users: 'Organisationen & Benutzer',
    reports: 'Meldungen',
    schedule: 'Zeitplan',
    training: 'Training',
    nowLive: 'Übertragung',
    users: 'Benutzer',
    workshop: 'Mein Workshop'
  },

  onboarding: {
    step1_title: 'Schritt 1 : Erzählen Sie uns ein wenig über sich selbst',
    step1_subtitle: 'Wie heißen Sie? Woher kommen Sie? Was ist Ihr Beruf?',
    step2_title:
      'Schritt 2 : Wählen Sie einige Fertigkeiten, die Sie an Ihnen verbessern könnten',
    step2_subtitle:
      'Als nächstes sagen Sie uns bitte, was Sie besser verstehen möchten. Vielleicht etwas, was Sie noch nicht komplett verstanden haben? Ein neues Interessenfeld? Was Ihr Partner macht?'
  },

  pagebuilder: {
    title: 'Lektion erstellen',
    subtitle:
      'Es ist an der Zeit, den Inhalt für Ihre Zielgruppe zu erstellen, von dem Sie von Ihnen lernen können.Fügen Sie doch Bilder, Videos und Diagramme hinzu',
    select_layout: 'Wählen Sie ein Seitenlayout',
    template1: 'leer',
    template2: 'Titel & Untertitel',
    template3: 'Titel & Text',
    template4: 'Titel, Medien & Text',
    template5: 'Titel & Medien',
    template6: 'Titel & Quiz'
  },

  pageconstructor: {
    title: 'Erstellen und Duplizieren von Lektionen',
    subtitle:
      ' Es ist an der Zeit, den Inhalt für Ihre Zielgruppe zu erstellen, von dem Sie von Ihnen lernen können.Fügen Sie doch Bilder, Videos und Diagramme hinzu '
  },

  passwordreset: {
    success_coded:
      'Wir haben einen Bestätigungscode an die von Ihnen angegebene E-Mail-Adresse gesendet, kopieren Sie bitte den Code in das Feld, um ein neues Passwort festzulegen.',
    success_changed:
      'Sie haben erfolgreich ein neues Kennwort festgelegt. Sie können sich jetzt anmelden.'
  },

  placeholders: {
    search: 'Suche',
    answer: 'Antwort',
    chat: 'Sag etwas...',
    code: 'Code',
    criterion: 'Kriterium',
    date_event: 'Datum & Uhrzeit auswählen',
    date_start: 'Startdatum',
    date_expire: 'Ablaufdatum',
    description: 'Beschreibung',
    elements: 'Sortieren von Elementen',
    email: 'E-Mail-Adresse',
    feedback_correct: 'positives Feedback',
    feedback_incorrect: 'Feedback von zu verbessernden Sachen',
    host: 'Leiter',
    keywords: 'Suche Schlüsselwörter',
    lesson_description: 'Lektionsbeschreibung',
    location: 'Standort',
    minutes: 'Minuten',
    name: 'Name',
    organiser: 'Autor',
    organization: 'Organisation',
    password: 'Passwort',
    phone: 'Telefonnumer',
    photo: 'Bild',
    picture: 'Foto',
    point: 'Punkt',
    position: 'Position',
    question: 'Frage',
    quiz: 'Quiz-Name',
    search_course: 'Suche nach einem Kurs',
    search_media: 'Suche nach Medien',
    search_name: 'Suche nach einem Namen',
    search_title: 'Suche nach einem Titel',
    search_training: 'Suche ein Training',
    settings: 'Einstellungen',
    tags: 'Tags',
    title: 'Titel',
    topics: 'Themen',
    username: 'Benutzername',
    keyword: 'Schlüsselwörter',
    max_500_characters: 'Max. 500 Zeichen ',
    no_description: 'Diese Datei enthält keine Beschreibung',
    no_keyword: 'Diese Datei hat keine Schlüsselwörter',
    no_topics: 'Für diese Datei ist kein Thema festgelegt.',
    feedback: 'Nachricht',
    contact: 'Wie können wir helfen?'
  },

  publishsettings: {
    title: 'Einstellungen zur Veröffentlichung',
    event_subtitle:
      'Hinzufügen von Einstellungen zu Ihrem Event, wer im Event angezeigt wird und wer mit Ihnen interagieren kann',
    assessment_subtitile:
      'Benennen Sie Ihre Bewertung und sagen Sie Ihrem Publikum, was Sie davon lernen können',
    course_subtitle:
      'Hinzufügen von Einstellungen zu Ihrem Kurs, wer angezeigt werden kann und wer mit Ihnen interagieren kann',
    disclaimer: `
    1.	Registrierung

    Mit der Registrierung als Nutzer/der Erstellung eines Kontos erklären Sie sich mit unseren [Benutzervereinbarung] eiverstanden und erkennen unsere [Datenschutzrichtlinien] an.

    2.	Kurs erstellen

    Durch die Nutzung unserer Tools und die Erstellung von Kursinhalten erklären Sie sich mit unseren [Benutzervereinbarung] einverstanden und erkennen unsere [Datenschutzrichtlinien] an.

    3.	Event erstellen

    Durch die Nutzung unserer Tools und die Erstellung eines Events erklären Sie sich mit unseren [Benutzervereinbarung] einverstanden und erkennen unsere [Datenschutzrichtlinien] an.

    4.	Erstellen einer Bewertung

    Durch die Nutzung unserer Tools und die Erstellung einer Bewertung erklären Sie sich mit unseren [Benutzervereinbarung] einverstanden und erkennen unsere [Datenschutzrichtlinien] an.`,
    dates_validation_error: 'Beide Datenh sollten ausgefüllt werden.',
    emails_validation_error: 'Die E-Mail-Liste ist ungültig.',
    specific_email_validation_error: 'Es ist nur eine E-Mail-Adresse zulässig.',
    i_agree_to_these: 'Ich stimme diesen zu ',
    terms_and_conditions: 'Geschäftsbedingungen.',
    PublicDescription:
      'Freie unabhängige Benutzer: (private und öffentliche Optionen).Ihr [Kurs| Event | Bewertung] wurde gespeichert und gilt standardmäßig als privat. Dies bedeutet, dass nur Sie diesen Inhalt sehen können. Um diesen Inhalt zu veröffentlichen, können Sie unten auswählen, ob er veröffentlicht werden soll. Dies bedeutet, dass der Kurs für alle Benutzer auf der Plattform verfügbar sein wird.',
    OrganisationsDescription:
      'Org-Benutzer: (Anstelle von ""Beenden"" verwenden Sie ""Veröffentlichen"" und sie werden zur  Veröffentlichungsseite wie andere Benutzer geleitet, aber ohne die Veröffentlichungsoptionen.) Ihr [Kurs| Event | Bewertung] wurde gespeichert und ist Privat. Dies bedeutet, dass nur Sie und die Administratoren in Ihrer Organisation es sehen können. Als Benutzer können Sie Ihre Inhalte nicht selbst in Ihrer Organisation veröffentlichen, da dies Administratorrechte erfordert. Wenden Sie sich an einen Ihrer Administratoren, und bitten Sie ihn, Ihre Inhalte öffentlich zu machen. Entweder in der gesamten Organisation, in bestimmten Gruppen innerhalb Ihrer Organisation oder für bestimmte Benutzer.',
    OrganisationGroupsDescription:
      'Org-Admins: (Für Inhalte, die innerhalb der Organisation gemacht werden: Privat, öffentlich, Organisation, Gruppen, für bestimmte Benutzer):Dieser [Kurs| Event | Bewertung] wurde gespeichert und gilt standardmäßig als privat. Dies bedeutet, dass nur Sie (und der Inhaltsersteller) diesen Inhalt sehen können. Um diesen Inhalt zu veröffentlichen, können Sie eine der folgenden Optionen auswählen. Das Veröffentlichen des Kurses unter "Öffentlich" bedeutet, dass er für alle Benutzer auf der Plattform verfügbar ist , auch für Benutzer außerhalb Ihrer Organisation. Die Veröffentlichung in der Organisation bedeutet, dass alle Benutzer innerhalb der Organisation es sehen können. Sie können es auch in 1 oder mehr Gruppen veröffentlichen, d. h., nur die Benutzer innerhalb dieser Gruppen können sie sehen.  Schließlich haben Sie die Möglichkeit, es für bestimmte Benutzer innerhalb und außerhalb Ihrer Organisation unter Verwendung ihrer E-Mail-Adresse des Sharelook-Kontos zu veröffentlichen.',
    SpecificUsersDescription:
      'Zahlende unabhängige Benutzer: (private, öffentliche und spezifische Benutzer). Ihr [Kurs| Event | Bewertung] wurde gespeichert und gilt standardmäßig als privat. Dies bedeutet, dass nur Sie diesen Inhalt sehen können. Um diesen Inhalt zu veröffentlichen, können Sie eine der folgenden Optionen auswählen. Die Veröffentlichung Ihres Kurses unter "Öffentlich" bedeutet, dass er allen Benutzern auf der Plattform zur Verfügung steht. Mit der Option Spezifische Benutzer können Sie Ihren Kurs mit bestimmten Benutzern teilen, indem Sie die E-Mail-Adresse verwenden, die mit ihrem Sharelook-Konto verknüpft ist.'
  },

  quiz: {
    quiz_empty: 'Sie haben noch kein Quiz erstellt',
    questions_empty: 'Sie haben noch keine Frage erstellt'
  },

  regions: {
    asia_pacific_australia: 'Australien',
    asia_pacific_india: 'Indien',
    asia_pacific_japan: 'Japan',
    asia_pacific_s_korea: 'Südkorea',
    asia_pacific_singapore: 'Singapur',
    asia_pacific_taiwan: 'Taiwan',
    eu_belgium: 'Belgien',
    eu_germany: 'Deutschland',
    eu_ireland: 'Irland',
    south_america_brazil: 'Brasilien',
    us_central_iowa: 'Iowa',
    us_east_s_carolina: 'South Carolina',
    us_east_virginia: 'Virginia',
    us_west_california: 'California',
    us_west_oregon: 'Oregon'
  },

  reports: {
    title: 'Meldungen',
    workshop_report: 'Workshop-Bericht',
    user_report: 'Benutzer-Bericht',
    organization_workshop: 'organisation werkstatt',
    course: 'Kurs',
    live_event: 'Live-Veranstaltung',
    workShop_graph: 'Grafik Werkstatt',
    organization_account: 'Konto Organisation',
    all: 'Alle',
    name: 'Name',
    id_number: 'ID-Nummer',
    email: 'Email',
    search_user_report: 'Benutzerbericht suchen',
    participant: 'Teilnehmer',
    participants: 'Teilnehmer',
    host: 'Gastgeber',
    username: 'Benutzername',
    event: 'Ereignis',
    quiz: 'Quiz',

    assessment: 'Bewertung',
    time: 'Zeit',
    no_recent_activity: 'Keine jüngste Aktivität',
    workshops: 'Workshops',
    courses: 'Kurse',
    live_events: 'Live-Veranstaltungen',
    live_assessments: 'Live-Bewertungen',
    quizzes: ' Ratespiele',
    overview: 'Überblick',
    by: ' von',
    views: 'Ansichten',
    status: 'Status',
    poll: 'Umfrage',
    comment: 'Kommentar',
    comments: 'Kommentare',
    no_comments: 'Keine Kommentare',
    no_polls: 'Keine Umfragen',
    live_date: 'Live-Datum',
    score: 'Partitur',
    question: 'Frage',
    submit: 'Einreichen',
    recent_activity: 'Jüngste Aktivität',
    ago: 'Vorbei',
    no_data_found: 'Keine Daten gefunden',
    start_end_live: 'Start / Ende live',
    join_leave_date: 'Beitreten / Verlassen',
    watched_time: 'Beobachtete Zeit',
    complete: 'Vollständig',
    in_complete: 'Un vollständig',
    today: 'Heute',
    seven_days: '7 Tage',
    last_month: 'Letzter Monat',
    one_year: '1 Jahr',
    all_time: 'Alle Zeit',
    report: 'Bericht',
    from_date: 'Von-Datum',
    to_date: 'Bis dato',
    all_workshop: 'Alle Workshop',
    all_participants: 'Alle Teilnehmer',
    live_assessment: 'Live-Beurteilung',
    users: 'Benutzer',
    download: 'herunterladen',
    recent: 'Kürzlich',
    WorkShop: 'Werkstatt',
    Activity: 'Aktivität',
    created_date: 'Erstellungsdatum',
    lessons: 'Lektionen',
    lesson: 'Lektion',
    completed: 'Abgeschlossen',
    report_download: 'Bericht herunterladen',
    this_month: 'Diesen Monat',
    search_course: 'Kurs suchen',
    participants_graph: 'Grafik Teilnehmer',
    viewed: 'Gesehen',
    enrolled: 'Eingeschrieben',
    description: 'Beschreibung',
    topic: 'Thema',
    analytic: 'Analytisch',
    quiz_list: 'Quiz-Liste',
    select_one: 'Eine auswählen',
    group_status: 'Gruppenstatus',
    duration_min: 'Dauer(min)',
    submit_poll: 'Umfrage einreichen',
    download_events: 'Veranstaltungen herunterladen',
    search_event: 'Veranstaltung suchen',
    duration: 'Dauer',
    total_participants: 'Total Teilnehmer',
    message: 'Nachricht',
    avg_message: 'Durchschnitt. Nachricht',
    per_message: 'per_Nachricht: '
  },

  signup: {
    success_step1:
      'Wir haben einen Bestätigungscode an die von Ihnen angegebene E-Mail-Adresse gesendet, kopieren Sie bitte den Code in das Feld, um Ihre Registrierung zu bestätigen.',
    success_step2: 'Danke. Sie sind erfolgreich registriert.',
    privacy: `Ich stimme Sharelook's <1> Allgemeine Geschäftsbedingungen</1> und <3> Datenschutzrichtlinien </3> zu`
  },

  stream: {
    emotion_impressive: 'Beeindruckend',
    emotion_unimpressive: 'nicht beeindruckend',
    message_not_started: 'Erscheint bald',
    message_running: 'Live',
    message_processing:
      'Wir verarbeiten derzeit die Live-Aufnahme für die Wiedergabe. Bitte schauen sie später nochmal vorbei um das Video zu sehen znd Bewertungen und Feedack zu schreiben.'
  },

  tabs: {
    audience: 'Publikum',
    course: 'Kursübersicht',
    details: 'Live-Event-Details',
    description: 'Beschreibung',
    host: 'Leiter',
    lesson: 'Lektionsübersicht',
    lessons: 'Lektionen',
    overview: 'Übersicht',
    page: 'Seiten-Editor',
    publish: 'Veröffentlichen',
    settings: 'Einstellungen zum Veröffentlichen',
    total_views: 'Alle Aufrufe',
    live_participates: 'Live-Teilnehmer',
    live_chats: 'Live-Chats',
    live_quiz: 'Live-Umfragen',
    trainer_rating: 'Trainerbewertung',
    trainer_times: 'Trainer Zeiten',
    courses_quizzes: 'Kurs-Quizze',
    user_list: 'Benutzerliste',
    discussion: 'Diskussion',
    notes: 'Meine Notizen'
  },

  topics: {
    SALES_TRAINING: 'Verkaufstraining',
    NEGOTIATION_TRAINING: 'Verhandlungstraining',
    CROSS_CULTURAL_AWARENESS: 'Interkulturelles Bewusstsein',
    NETWORKING_SKILLS: 'Netzwerkfähigkeiten',
    SOFTWARE_ARCHITECTURE: 'Softwarearchitektur',
    PROJECT_MANAGEMENT: 'Projektmanagement',
    DIGITAL_TRANSFORMATION: 'Digitale Transformation',
    IT_CONSULTING: 'IT-Beratung',
    MANAGEMENT_CONSULTING: 'Management-Beratung',
    HR_CONSULTING: 'HR-Beratung',
    INTERNET_OF_THINGS: 'Internet of Things (IoT)',
    CYBERSECURITY: 'Cybersecurity',
    PRIVACY_AND_GDRP: 'Datenschutz und DSGVO'
  },

  training: {
    title: 'Training',
    title_assessment: 'Bewertungen',
    subtitle: 'Kurse und Live-Events',
    enrolled_title: 'Eingeschriebene Kurse und Live-Events',
    enrolled_subtitle:
      'Kurse und Live-Events, für die Sie sich angemeldet haben',
    enrolled_message:
      'Sie sind derzeit nicht an Kursen und Veranstaltungen angemeldet.',
    past_live_event_title: 'Vergangene Live-Events',
    past_live_event_subTitle: 'Abgeschlossene Live-Events',
    past_live_event: 'Es gibt derzeit keine vergangenen Live-Events',
    recomended_title: 'Empfohlene Kurse und Live-Events',
    now_live_event_title: 'Jetzt Live',
    now_live_event_subTitle:
      'Events, die live sind oder sehr bald live sein werden',
    now_live_event: 'Derzeit gibt es keine Live-Events.',
    webinar_event_title: 'Webinar-Kanal',
    webinar_event_subTitle: 'Abgeschlossene Webinare',
    webinar_event: 'Derzeit gibt es keine Webinare.',
    p2p_event_title: 'Coaching-Sitzung',
    p2p_event_subTitle: 'Abgeschlossene Coaching-Sitzungen',
    p2p_event: 'Derzeit gibt es keine Coaching-Sitzung.',

    now_live_assessment_title: 'Jetzt Live',
    now_live_assessment_subTitle:
      'Bewertungen, die live sind, oder bald live sein werden',
    now_live_assessment_message: 'Derzeit gibt es keine Live-Bewertungen.',

    upcoming_assessment_title: 'Geplante Live-Bewertungen',
    upcoming_assessment_subtitle: 'Geplante Live-Bewertungen',
    upcoming_assessment_message:
      'Derzeit gibt es keine geplanten Live-Bewertungen.',

    past_live_assessment_title: 'Vergangene Live-Bewertungen',
    past_live_assessment_subTitle: 'Abgeschlossene Live-Bewertungen',
    past_live_assessment_message:
      'Es gibt derzeit keine vergangenen Live-Bewertungen.',

    /**
     * <0/> get index 0 of data from Trans component
     * exp -->
     * <Trans i18nKey='training:recomended_subtitle' >
     *    <Navlink>{...something}</Navlink> --> is index 0 of Trans component
     *    <h1>{...something}</h1> --> is index 1 of Trans component
     * </Trans>
     */
    recomended_subtitle:
      'Wir haben Kurse und Live-Events basierend auf Ihrem <0><0/></0> ausgewählt',
    /**
     * and we also can get data from index to replace in recommended_subtitle by like above string.
     * for exp -->
     * at the end of the recomended_subtitle above will get data from index of component to display
     * <0><0/></0> mean get data index 0 of Trans component above = <Navlink> and <0/> get data index 0 from <Navlink> = {...something}
     */
    search: 'Training suchen',
    search_assessment: 'Bewertung suchen',
    recomended_message: 'Derzeit gibt es keine empfohlenen Kurse und Events.'
  },

  warnings: {
    delete: 'Sind Sie sicher?'
  },

  workshop: {
    title: 'Mein Workshop',
    subtitle:
      'Erstellen Sie einen Kurs oder veranstalten Sie ein eigenes Live-Event',
    new_course: 'Erstellen eines neuen Kurs',
    new_event: 'Erstellen eines neuen Live-Events',
    new_assessment: 'Erstellen einer neuen Live-Bewertung',
    courses_title: 'Erstellte Kurse & Live-Events',
    courses_subtitle:
      'Bearbeiten & Veröffentlichen Ihres erstellten Kurses und Planen von Live-Events',
    tags_course: 'Kurs',
    tags_live: 'Live-Event',
    tags_assessment: 'Live-Bewertung'
  },

  help_center: {
    sidebar: {
      title: 'Wie können wir Ihnen helfen?',
      back_to: 'Dashboard'
    },
    menu: {
      help_center: 'Hilfe-Center',
      whats_new: 'Was gibt es Neues?',
      feedback: 'Feedback',
      feedback_title: 'Senden Sie feedback an sharelook',
      terms_and_conditions: 'Allgemeine Geschäftsbedingungen',
      privacy_policy: 'Datenschutzrichtlinien',
      contact_us: 'kontaktieren Sie uns'
    },
    get_started: {
      title: 'Starten Sie mit share look',
      description: {
        main:
          'WIR HABEN DAS LIVE-VIDEO IN EINEN BENUTZERFREUNDLICHEN KURS-BUILDER INTEGRIERT',
        tagline:
          'Mit dem patentierten Live-Übertragungs-System von ShareLook können Sie Engagement aufbauen, lernen und den Wissensaustausch maximieren..'
      }
    },
    whats_new: {
      title: 'Starten Sie mit share look',
      description: {
        main:
          'Wussten Sie, dass das Erstellen von Storytelling-Stil-Lernen in kurzer Zeit und nicht so teuer möglich ist?  Sie sollten den ShareLook Kurs-Builder und die Ihre entsprechenden Talente kombinieren. Sie können uns fragen: "WIE”',
        tagline: ''
      }
    },
    create_workshop: 'So erstellen Sie einen Workshop',
    create_course: 'So erstellen Sie einen Kurs',
    create_user: 'So erstellen Sie ein Benutzerkonto',
    create_group: 'So erstellen Sie eine Gruppe',
    create_webinar: 'Erstellen eines Webinars',
    create_live_event: 'Wie man ein Live-Event erstellt',
    create_live_assessment: 'So erstellen Sie Live-Bewertungen',
    create_live_on_mobile: 'Live auf Mobilgeräten erstellen',
    personalize_profile: 'Personalisieren eines Profils',
    manage_group: 'Gruppe verwalten',
    report: 'Melden',
    add_annotations: 'Hinzufügen von Anmerkungen/Popups',
    add_audio: 'Wie man Audio hinzufügt',
    add_header_and_footer: 'Hinzufügen von Header & Footer',
    add_image: 'Wie man ein Bild hinzufügt',
    add_quiz_content: 'So fügen Sie Quizinhalte hinzu',
    add_text_content: 'Hinzufügen von Textinhalten',
    add_user: 'Wie man Benutzer hinzufügt',
    add_video: 'Wie man ein Video hinzufügt',
    clone_course: 'Wie man den Kurse dupliziert',
    find_live_broadcast: 'So finden Sie Live-Übertragungen',
    contact_us: {
      title: 'kontaktieren Sie uns',
      subject_placeholder: 'Geben Sie ein Betreff ein*',
      content_placeholder: 'Geben Sie eine Nachricht ein*',
      subject_required: '*Betreff ist erforderlich',
      content_required: '*Nachricht ist erforderlich',
      submit: 'Senden',
      cancel: 'Abbrechen',
      sent: 'gesendet'
    },
    feedback: {
      title: 'Senden Sie feedback an sharelook',
      attach_title: 'Anfügen einer Datei',
      choose: 'Datei auswählen',
      detail: 'Die Datei wird mit dem Support-Team geteilt',
      content_placeholder: 'Geben Sie eine Nachricht ein*',
      content_required: '*Nachricht ist erforderlich',
      submit: 'Senden',
      cancel: 'Abbrechen',
      sent: 'gesendet'
    }
  },
  discussion: {
    join_discussion: 'Der Diskussion beitreten'
  },
  notes: {
    title: 'Meine Notizen',
    add_note: 'Neue Notiz hinzufügen'
  }
}
