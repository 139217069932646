import React, { Fragment, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
  Form,
  Icon,
  Button,
  Spin,
  message,
  Tooltip,
  Row,
  Col,
  Checkbox
} from 'antd'
import api from '../../../services/api'
import './DiscussionCreateMain.scss'
import { FormHeader } from './components'
import { AddGuest } from './components/AddGuest'
import { AddCover } from './components/AddCover'
import { SelectDate } from './components/SelectDate'
import { AddAudioLanguage } from './components/AddAudioLanguage'
import { Description } from './components/Description'
import { useSelector } from 'react-redux'
import actions from '../../../store/actions'
import history from '../../../history'
import moment from 'moment'
import AudioList from './components/AudioList/AudioList'
import AttachFile from './components/AttachFile/AttachFile'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import MarketPlaceForm from './components/MarketPlaceForm/MarketPlaceForm'
import Setting from './components/Setting/Setting'
import { general } from '../../../constants'
import ReactSVG from 'react-svg'
import { marketplace } from '../../Courses/Create/nestedComponents/CourseCreateMain/images'

const DiscussionCreateMain = props => {
  const activeOrganizationId = useSelector(
    state => state.user.info.active_organization_id
  )

  const user = useSelector(state => state.user.info)
  const [users, setUsers] = useState([])
  const [dateTime, setDateTime] = useState({
    date: null,
    duration: null
  })
  const [attachedData, setAttachedData] = useState({
    attachModal: false,
    filenames: [],
    pdfFiles: [],
    attachmentIds: []
  })
  const [sendMail, setSendMail] = useState({
    isAdminOnly: false,
    isSendMail: false
  })
  const [isDisabled, setIsDisabled] = useState(true)
  const [discussionRoomType, setDiscussionRoomType] = useState('live')
  const [pdfIndex, setPdfIndex] = useState(0)
  const [organizationIds, setOrganizationIds] = useState([activeOrganizationId])
  const [checkedTerms, setChangeTerms] = useState(false)
  const [selectedType, setSelectedType] = useState(null)
  const [modalOpen, setModal] = useState(false)
  const [selectedItem, setSelectedItem] = useState(null)
  const [selectedCover, setSelectedCover] = useState({
    id: '',
    link: '',
    file_size: '',
    filename: ''
  })
  const [selectedGuests, setSelectedGuests] = useState([])
  const [selectedDate, setSelectedDate] = useState(null)
  const [selectedStartTime, setSelectedStartTime] = useState(null)
  const [selectedEndTime, setSelectedEndTime] = useState(null)
  const [description, setDescription] = useState('')
  const [roomName, setRoomName] = useState('')
  const [isRecording, setIsRecording] = useState(true)
  const [isAiEnabled, setIsAiEnabled] = useState(false)
  const [coverError, setCoverError] = useState(false)
  const [roomNameError, setRoomNameError] = useState(false)
  const [hostError, setHostError] = useState(false)
  const [dateError, setDateError] = useState(false)
  const [isFormValid, setIsFormValid] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const [isPublishing, setIsPublishing] = useState(false)
  const [isFetching, setIsFetching] = useState(false)
  const [podcastId, setPodcastId] = useState('')
  const [creatorId, setCreatorId] = useState('')
  const [audioUrl, setAudioUrl] = useState({
    url: '',
    filename: '',
    is_ai_enabled: false
  })
  const [podcastAudio, setPodcastAudio] = useState({ list: [], status: true })
  const [isEnd, setIsEnd] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [aiLanguage, setAiLanguage] = useState('en-US')
  const [isHostOnly, setIsHostOnly] = useState(false)
  const [isMarketPlace, setMarketPlace] = useState(false)
  const [marketPlaceData, setMarketPlaceData] = useState({
    bio: undefined,
    category_id: '',
    course_description: '',
    course_obj: '',
    course_outline: '',
    group_id: null,
    is_free: false,
    is_shared_profile: false,
    linkedin: undefined,
    price: 5,
    user_id: '',
    username: '',
    video_link: ''
  })
  const [isUploading, setIsUploading] = useState({ status: false, type: '' })
  const [isDownLoading, setIsDownLoading] = useState({
    status: false,
    id: '',
    title: ''
  })
  const { t } = useTranslation()
  useEffect(() => {
    const { id = null } = props.match.params

    if (id) {
      setPodcastId(id)
      getDiscussionRoomById(id)
    }
  }, [])

  useEffect(() => {
    validateForm()
  }, [
    selectedGuests,
    selectedDate,
    selectedStartTime,
    selectedEndTime,
    selectedCover,
    roomName
  ])

  useEffect(() => {
    let orgIds = [
      ...organizationIds,
      ...selectedGuests.map(guest => guest.active_organization_id),
      activeOrganizationId
    ]
    orgIds.filter((id, index) => orgIds.indexOf(id) === index)
    setOrganizationIds(orgIds)
  }, [selectedGuests])

  const getDiscussionRoomById = async id => {
    setIsFetching(true)
    api.events
      .getById(id)
      .then(res => {
        if (res.data.event_type !== 'discussion_room') {
          history.push(`/events/${id}/edit`)
          return
        }
        if (res.data.hosts) {
          setSelectedGuests(res.data.hosts)
        }
        if (res.data.discussion_room_type) {
          setDiscussionRoomType(res.data.discussion_room_type)
        }
        setDescription(res.data.description)
        setRoomName(res.data.title)
        setSelectedCover({
          id: res.data.cover.id,
          link: res.data.cover.link,
          filename: res.data.cover.filename,
          file_size: res.data.cover.file_size
        })
        setDateTime({
          date: res.data.start_date_time,
          duration: res.data.duration
        })
        getAttachmentDetail(res.data.attachments)
        if (res.data.marketplace.course_obj) {
          validateMarketPlaceData(res.data.marketplace)
          setMarketPlaceData(res.data.marketplace)
        }
        if (res.data.setting) {
          setOrganizationIds(res.data.setting.organization_ids)
        }
        if (res.data.privacy) {
          if (res.data.privacy.publish_option === general.PUBLIC) {
            handleSelectedType(1)
          } else if (res.data.privacy.publish_option === general.ORGANIZATION) {
            handleSelectedType(2)
          } else if (res.data.privacy.publish_option === general.MARKETPLACE) {
            handleSelectedType(4)
          }
        }
        setIsEnd(res.data.is_finished)
        setIsHostOnly(res.data.setting.is_host_only)
        setIsRecording(res.data.is_recording)
        setIsAiEnabled(res.data.is_ai_enabled)
        setCreatorId(res.data.creator_id)
        if (res.data.audio[0]) {
          setIsEdit(true)
          setAudioData(res.data.audio)
        } else {
          setIsFetching(false)
        }
      })
      .catch(err => {
        setIsFetching(false)
      })
  }
  const getAttachmentDetail = async ids => {
    if (ids && ids.length > 0) {
      let tempName = []

      for (const id of ids) {
        const response = await api.library.getMediaInfoById(id)
        tempName.push(response.data)
      }

      const uniqueName = []
      const encounteredIds = new Set()

      for (const obj of tempName) {
        if (!encounteredIds.has(obj.id)) {
          uniqueName.push(obj)
          encounteredIds.add(obj.id)
        }
      }

      setAttachedData({
        ...attachedData,
        attachModal: false,
        attachmentIds: ids,
        filenames: uniqueName
      })
    }
  }
  const handleSelectAudio = async audio => {
    if (audio.id !== isDownLoading.id) {
      setAudioUrl({
        url: audio.downloadble_link,
        filename: audio.filename,
        is_ai_enabled: audio.is_ai_enabled
      })
      setIsDownLoading({ status: true, id: audio.id, title: audio.title })
      await setTimeout(() => {
        setIsDownLoading({ status: false, id: audio.id, title: audio.title })
      }, 300)
    }
  }
  const formatTime = timeInSeconds => {
    const minutes = Math.floor(timeInSeconds / 60)
    const seconds = Math.floor(timeInSeconds % 60)
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`
  }
  const setAudioData = async data => {
    try {
      let audioList = await Promise.all(
        data.map(async (audio, i) => {
          try {
            let res = await getDuration(audio.id)
            if (i === 0) {
              /// playing the first audio default
              setIsDownLoading({
                status: false,
                id: audio.id,
                title: audio.title
              })
              setAudioUrl({
                url: res.downloadble_link,
                filename: audio.filename,
                is_ai_enabled: audio.is_ai_enabled
              })
            }
            return {
              ...audio,
              duration: res.duration,
              downloadble_link: res.downloadble_link
            }
          } catch (error) {
            return {
              ...audio,
              duration: 0,
              downloadble_link: ''
            }
          }
        })
      )
      setPodcastAudio({ list: audioList, status: false })
      setIsFetching(false)
    } catch (error) {
      setIsFetching(false)
      setPodcastAudio({ list: [], status: false })
      console.error('Error processing audio data:', error)
    }
  }

  const getDuration = id => {
    return new Promise((resolve, reject) => {
      api.library
        .download({ id })
        .then(({ data }) => {
          getAudioDuration(data.downloadble_link)
            .then(duration => {
              resolve({
                duration: duration,
                downloadble_link: data.downloadble_link
              })
            })
            .catch(e => {
              reject(e)
            })
        })
        .catch(err => {
          reject(err)
        })
    })
  }

  const getAudioDuration = async url => {
    return new Promise((resolve, reject) => {
      const audio = new Audio(url)
      audio.addEventListener('loadedmetadata', () => {
        resolve(formatTime(audio.duration))
      })

      audio.addEventListener('error', error => {
        reject(error)
      })
    })
  }
  const validateForm = () => {
    const isFormValid =
      selectedDate &&
      selectedStartTime &&
      (podcastId || selectedGuests.length > 0) &&
      selectedEndTime &&
      selectedCover.id &&
      (podcastId || roomName.length > 0)
    console.log('chat validating form...', isFormValid)

    // Check if end time is before start time
    if (
      selectedStartTime &&
      selectedEndTime &&
      selectedStartTime > selectedEndTime
    ) {
      message.warning(`${t('v4:end_time_cannot_start')}`)
    } else {
      setIsFormValid(isFormValid)
    }
  }

  const formatDataForAPI = (data, isCover, isPublish) => {
    const {
      roomName,
      guests,
      date,
      startTime,
      endTime,
      coverId,
      description
    } = data
    // Duration calculation, assuming startTime and endTime are in "HH:MM" format
    const startMinutes =
      parseInt(startTime.split(':')[0]) * 60 + parseInt(startTime.split(':')[1])
    const endMinutes =
      parseInt(endTime.split(':')[0]) * 60 + parseInt(endTime.split(':')[1])
    const duration = isNaN(endMinutes - startMinutes)
      ? 20
      : endMinutes - startMinutes
    const participantIds = []
    const participantEmails = []
    const hostEmails = []
    const guestEmails = guests.map(guest => guest.email)
    const guestIds = guests.map(guest => guest.id)
    let currentDateTime = moment()
    // Parse the end time and extract hours and minutes
    let [endHours, endMins] = endTime.split(':').map(Number)
    // Add the end time to the given date
    let combinedDateTime = moment(date)
      .startOf('day')
      .clone()
      .set({ hour: endHours, minute: endMins })
    let hasPassed = currentDateTime.isAfter(combinedDateTime)

    //converting date in utc
    const newDate = moment(date)
    const dateTimeWithTime = newDate.set({
      hour: parseInt(startTime.split(':')[0]),
      minute: parseInt(startTime.split(':')[1]),
      second: 0,
      millisecond: 0
    })
    // Format the resulting datetime
    const startDate = moment
      .utc(dateTimeWithTime, 'DD/MM/YYYY HH:mm:ss')
      .toDate()
    return {
      participant_emails: participantEmails,
      participant_ids: participantIds,
      guest_ids: [],
      attendee_email: undefined,
      attendee_id: undefined,
      badge_id: null,
      cert_id: null,
      cover_id: coverId || 48773,
      description: description,
      duration: duration,
      encoder_type: 'chime_conference',
      event_type: 'discussion_room',
      discussion_room_type: discussionRoomType,
      has_breakout_room: false,
      host: user.email,
      host_emails: guestEmails,
      host_ids: guestIds,
      isSendMail: sendMail.isSendMail,
      isAdminOnly: sendMail.isAdminOnly,
      isSendMailToHost: false,
      is_ai_enabled: isAiEnabled,
      is_chat: true,
      is_live_assessment: false,
      is_poll: false,
      is_finished: isPublish ? isPublish : hasPassed,
      is_recording: isRecording,
      is_transcribed: undefined,
      live_assessments: [],
      marketplace: marketPlaceData,
      marketplace_option: undefined,
      no_of_people_conf: 0,
      pass_score: 0,
      privacy: {
        emails: hostEmails,
        group_ids: [],
        is_admin_only: false,
        // make the podcast public when the event is end and private when its live
        is_host_only: isCover
          ? isHostOnly
          : isPublish
          ? false
          : !hasPassed
          ? true
          : !isEnd,
        is_marketplace: selectedType === 4,
        is_public: selectedType === 1,
        organization_ids: isPublish
          ? selectedType
            ? selectedType === 4
              ? [1]
              : organizationIds.length !== 0
              ? organizationIds
              : [activeOrganizationId]
            : []
          : [],
        publish_option:
          selectedType === 4 ? general.MARKETPLACE : general.ORGANIZATION
      },
      start_date_time: startDate, //utc date
      title: roomName,
      topics: [],
      transcribe_locale: undefined,
      attachments: attachedData.attachmentIds
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        api.organizations
          .getUsersByOrganizationId(activeOrganizationId)
          .then(res => {
            const usersData = res.data.map(val => val.user)
            setUsers(usersData)
          })
      } catch (err) {
        console.error(err)
      }
    }

    fetchData()
  }, [activeOrganizationId])

  const handleSubmit = async autoSave => {
    if (!roomName) {
      message.warning(`${t('v3:room_required_name')}`)
      setRoomNameError(true)
      return
    }
    if (selectedGuests.length === 0) {
      message.warning(`${t('v3:add_guest_list')}`)
      setHostError(true)
      return
    }
    if (!selectedDate || !selectedStartTime || !selectedEndTime) {
      message.warning(`${t('v4:start_date_time_interval')}`)
      setDateError(true)
      return
    }
    if (!selectedCover.id) {
      message.warning(`${t('v3:required_cover_image')}`)
      setCoverError(true)
      return
    }
    if (isFormValid) {
      const formData = {
        roomName: roomName,
        guests: selectedGuests,
        date: selectedDate,
        startTime: selectedStartTime,
        endTime: selectedEndTime,
        coverId: selectedCover.id,
        description: description
      }
      const apiData = formatDataForAPI(formData, false, false)
      setIsSaving(true)
      if (podcastId) {
        api.events
          .updateById(podcastId, { ...apiData })
          .then(response => {
            setTimeout(() => {
              setIsSaving(false)
              !autoSave &&
                message.success(`${t('v4:podcast_updated_successful')}`)
              !autoSave && history.push(`/podcast/${response.data.id}`)
            }, 2000)
          })
          .catch(error => {
            setIsSaving(false)
            message.error(
              typeof error === String
                ? error
                : error.errorMessage || 'Failed to update podcast!'
            )
          })
      } else {
        api.events
          .create({
            ...apiData
          })
          .then(response => {
            setTimeout(() => {
              setIsSaving(false)
              message.success(`${t('v4:podcast_created_successful')}`)
              history.push(`/podcast/${response.data.id}`)
            })
          }, 1000)

          .catch(error => {
            setIsSaving(false)
            message.error(
              typeof error === String
                ? error
                : error.errorMessage || 'Failed to create  podcast!'
            )
          })
      }
    }
  }
  const submitCustomPodcast = async (
    autoSave,
    marketPlaceSave,
    coverId,
    isPublish
  ) => {
    if (!roomName) {
      message.warning(`${t('v3:room_required_name')}`)
      setRoomNameError(true)
      return
    }
    if (!selectedCover.id) {
      message.warning(`${t('v3:required_cover_image')}`)
      setCoverError(true)
      return
    }
    let date = new Date()
    let startTime = new Date(date.getTime() - 5 * 60000)
    const formatTime = dateObj => {
      let hours = dateObj
        .getHours()
        .toString()
        .padStart(2, '0')
      let minutes = dateObj
        .getMinutes()
        .toString()
        .padStart(2, '0')
      return `${hours}:${minutes}`
    }
    const formData = {
      roomName: roomName,
      guests: selectedGuests,
      date: date,
      startTime: formatTime(startTime),
      endTime: formatTime(date), // Current time as endTime
      coverId: coverId ? coverId : selectedCover.id,
      description: description
    }
    const apiData = formatDataForAPI(formData, false, isPublish)
    setIsPublishing(isPublish)
    setIsSaving(!isPublish)
    if (!autoSave) {
      api.events
        .create({
          ...apiData
        })
        .then(response => {
          apiData.is_finished = true
          api.events
            .updateById(response.data.id, { ...apiData })
            .then(response => {
              setTimeout(() => {
                setIsSaving(false)
                message.success('Podcast successfully created.')
                history.push(`/podcast/${response.data.id}/edit`)
                setPodcastId(response.data.id)
                getDiscussionRoomById(response.data.id)
              })
            })
            .catch(error => {
              setIsSaving(false)
              message.error(
                typeof error === String
                  ? error
                  : error.errorMessage || 'Failed to update podcast!'
              )
            })
        }, 1000)

        .catch(error => {
          setIsSaving(false)
          message.error(
            typeof error === String
              ? error
              : error.errorMessage || 'Failed to create  podcast!'
          )
        })
    } else {
      api.events
        .updateById(podcastId, { ...apiData })
        .then(response => {
          setTimeout(() => {
            setIsSaving(false)
            setIsPublishing(false)
            if (isPublish) {
              message.success(`${t('v4:podcast_published_successful')}`)
              history.push(`/podcast/${response.data.id}/play`)
            }
            if (!marketPlaceSave) {
              message.success(`${t('v4:podcast_updated_successful')}`)
              history.push(`/podcast/${response.data.id}/play`)
            }
          })
        })
        .catch(error => {
          setIsSaving(false)
          setIsPublishing(false)
          message.error(
            typeof error === String
              ? error
              : error.errorMessage || 'Failed to update podcast!'
          )
        })
    }
  }
  const handlePublish = async () => {
    if (discussionRoomType === 'custom') {
      submitCustomPodcast(true, true, false, true)
      return
    }
    if (!selectedDate || !selectedStartTime || !selectedEndTime) {
      message.warning(`${t('v3:podcast_start_date_time')}`)
      setDateError(true)
      return
    }
    if (!selectedCover.id) {
      message.warning(`${t('v3:required_cover_image')}`)
      setCoverError(true)
      return
    }
    if (isFormValid) {
      const formData = {
        roomName: roomName,
        guests: selectedGuests,
        date: selectedDate,
        startTime: selectedStartTime,
        endTime: selectedEndTime,
        coverId: selectedCover.id,
        description: description
      }
      const apiData = formatDataForAPI(formData, false, true)
      setIsPublishing(true)

      if (isAiEnabled && podcastAudio.list.length > 0) {
        try {
          for (const audio of podcastAudio.list) {
            let data = {
              id: audio.id,
              isAiEnabled: isAiEnabled,
              ai_language: aiLanguage
            }
            await api.library.updateMedia(data.id, data)
          }
        } catch (error) {
          setIsPublishing(false)
          message.error(
            typeof error === 'string'
              ? error
              : error.errorMessage || 'Failed to enable AI!'
          )
        }
      }
      api.events
        .updateById(podcastId, { ...apiData })
        .then(response => {
          setTimeout(() => {
            setIsPublishing(false)
            message.success(`${t('v4:podcast_published_successful')}`)
            history.push(`/podcast/${response.data.id}/play`)
          }, 2000)
        })
        .catch(error => {
          setIsPublishing(false)
          message.error(
            typeof error === String
              ? error
              : error.errorMessage || 'Failed to publish  podcast!'
          )
        })
    }
  }
  const handleSubmitCover = async coverId => {
    if (discussionRoomType === 'custom') {
      submitCustomPodcast(true, true, coverId)
      return
    }
    if (!roomName) {
      message.warning(`${t('v3:room_required_name')}`)
      setRoomNameError(true)
      return
    }
    if (selectedGuests.length === 0) {
      message.warning(`${t('v3:add_guest_list')}`)
      setHostError(true)
      return
    }
    if (!selectedDate || !selectedStartTime || !selectedEndTime) {
      message.warning(`${t('v3: podcast_start_date_time')}`)
      setDateError(true)
      return
    }
    if (!selectedCover.id) {
      message.warning(`${t('v3:required_cover_image')}`)
      setCoverError(true)
      return
    }
    if (isFormValid) {
      const formData = {
        roomName: roomName,
        guests: selectedGuests,
        date: selectedDate,
        startTime: selectedStartTime,
        endTime: selectedEndTime,
        coverId: coverId,
        description: description
      }
      const apiData = formatDataForAPI(formData, true, false)
      api.events
        .updateById(podcastId, { ...apiData })
        .then(response => {
          message.success(`${t('v4:podcast_cover_updated')}`)
        })
        .catch(error => {
          setIsSaving(false)
          message.error(
            typeof error === String
              ? error
              : error.errorMessage || 'Failed to update  podcast cover!'
          )
        })
    }
  }
  const setMultipleLocal = async data => {
    let tempList = []
    podcastAudio.list.forEach(item => {
      tempList.push(item)
    })
    let audioList = await Promise.all(
      data.map(async (audio, i) => {
        try {
          let res = await getDuration(audio.id)
          return {
            ...audio,
            duration: res.duration,
            downloadble_link: res.downloadble_link
          }
        } catch (error) {
          return {
            ...audio,
            duration: 0,
            downloadble_link: ''
          }
        }
      })
    )
    tempList.reverse()
    tempList = [...audioList, ...tempList]
    setIsDownLoading({
      status: false,
      id: tempList[0].id,
      title: tempList[0].title
    })
    setAudioUrl({
      url: tempList[0].downloadble_link,
      filename: tempList[0].filename,
      is_ai_enabled: tempList[0].is_ai_enabled
    })
    setPodcastAudio({ list: tempList, status: false })
    setTimeout(() => {
      setIsUploading({ status: false, type: 'machine' })
      message.success(t('v4:audio_modal.msg_success'))
    }, 500)
  }
  const setLocalAudio = async (audio, oldId) => {
    try {
      let res = await getDuration(audio.id)
      setIsDownLoading({
        status: false,
        id: audio.id,
        title: audio.title
      })
      setAudioUrl({
        url: res.downloadble_link,
        filename: audio.filename,
        is_ai_enabled: audio.is_ai_enabled
      })
      let tempList = []
      if (!oldId) {
        tempList.push({
          ...audio,
          duration: res.duration,
          downloadble_link: res.downloadble_link
        })
      }
      podcastAudio.list.forEach(item => {
        if (oldId === item.id) {
          tempList.push({
            ...audio,
            duration: res.duration,
            downloadble_link: res.downloadble_link
          })
        } else {
          tempList.push(item)
        }
      })
      setIsAiEnabled(true)
      setPodcastAudio({ list: tempList, status: false })
    } catch (error) {}
  }
  const removeLocalAudio = async oldId => {
    try {
      let tempList = []
      podcastAudio.list.forEach(item => {
        if (oldId === item.id) {
          return
        }
        tempList.push(item)
      })
      setPodcastAudio({ list: tempList, status: false })
    } catch (error) {}
  }
  const onHandleDelete = id => {
    const filteredIds = attachedData.attachmentIds.filter(i => i !== id)
    const filteredFiles = attachedData.filenames.filter(i => i.id !== id)
    setAttachedData({
      ...attachedData,
      attachmentIds: filteredIds,
      filenames: filteredFiles
    })
  }
  const checkedCompletUpload = item => {
    const { uploadSuccess } = props.library
    const filterSuccessFiles = _.filter(
      uploadSuccess,
      ele => ele.id === item.id
    )
    return !(uploadSuccess.length && filterSuccessFiles.length)
  }
  const onDropPdf = dropped => {
    const file = dropped[0]
    if (!file) {
      return
    }
    const { clearUploadList, upload } = props
    const newFiles = attachedData.pdfFiles.concat(dropped)
    setAttachedData({
      ...attachedData,
      attachModal: false,
      pdfFiles: newFiles
    })
    dropped.forEach(file => {
      upload(file)
        .then(response => {
          setPDF(response)
        })
        .then(() => {
          clearUploadList()
        })
    })
  }
  const setPDF = file => {
    const filterIds = attachedData.attachmentIds.filter(i => i !== file.id)
    const filterNames = attachedData.filenames.filter(i => i.id !== file.id)
    setAttachedData({
      ...attachedData,
      attachModal: false,
      attachmentIds: [...filterIds, file.id],
      filenames: [...filterNames, file]
    })
    setPdfIndex(pdfIndex + 1)
  }
  useEffect(() => {
    if (discussionRoomType === 'custom' && pdfIndex >= 1) {
      submitCustomPodcast(true, true)
    } else if (discussionRoomType === 'live' && pdfIndex >= 1) {
      handleSubmit(true)
    }
  }, [pdfIndex])
  const hidePreviewModal = () => {
    setSelectedItem(null)
  }
  const handleFileInsert = item => {
    setModal(false)
    setSelectedItem(null)
  }
  const handleInsertFile = async (
    { rows: privateData },
    publicMedia,
    orgMedia
  ) => {
    let tempData = privateData
    if (publicMedia) {
      const { rows: publicData } = publicMedia
      tempData = [...privateData, ...publicData]
    }

    if (orgMedia) {
      const { rows: orgData = [] } = orgMedia
      tempData = [...privateData, ...orgData]
    }

    let tempId = [...attachedData.attachmentIds]
    let tempName = [...attachedData.filenames]

    await tempData.forEach(file => {
      if (file.isSelected) {
        tempId.push(file.id)
        tempName.push(file)
      }
    })
    tempId = tempId.filter((id, index) => tempId.indexOf(id) === index)

    const uniqueName = []
    const encounteredIds = new Set()

    for (const obj of tempName) {
      if (!encounteredIds.has(obj.id)) {
        uniqueName.push(obj)
        encounteredIds.add(obj.id)
      }
    }
    setAttachedData({
      ...attachedData,
      attachModal: false,
      attachmentIds: tempId,
      filenames: uniqueName
    })
    setModal(false)
  }
  const handleRemoveSelected = () => {}
  const saveMarketPlace = async () => {
    let valid = await validateMarketPlaceData(marketPlaceData)
    if (valid) {
      if (discussionRoomType === 'live') {
        handleSubmit(true)
      } else if (discussionRoomType === 'custom') {
        submitCustomPodcast(true, true)
      }
    } else {
      message.warn(t('v4:message_marketplace'))
    }
  }
  const validateMarketPlaceData = data => {
    if (
      data.category_id === '' ||
      data.course_description === '' ||
      data.course_obj === '' ||
      data.course_outline === '' ||
      data.video_link === '' ||
      (data.is_free && parseInt(data.price) <= 0)
    ) {
      setIsDisabled(true)
      return false
    } else {
      setIsDisabled(false)
      setMarketPlace(false)
      return true
    }
  }
  const handleSelectedType = id => {
    setSelectedType(id)
  }
  const onDragDrop = dropResult => {
    const { removedIndex, addedIndex } = dropResult
    if (removedIndex !== null && addedIndex !== null) {
      let newSequences = [...podcastAudio.list]
      const [removed] = newSequences.splice(removedIndex, 1)
      newSequences.splice(addedIndex, 0, removed)
      setPodcastAudio({ list: newSequences, status: false })
      updateAudioSequence(newSequences)
    }
    return null
  }
  const updateAudioSequence = newSequences => {
    let formData = {
      roomName: roomName,
      guests: selectedGuests,
      date: selectedDate,
      startTime: selectedStartTime,
      endTime: selectedEndTime,
      coverId: selectedCover.id,
      description: description
    }
    if (discussionRoomType === 'custom') {
      let date = new Date()
      let startTime = new Date(date.getTime() - 5 * 60000)
      const formatTime = dateObj => {
        let hours = dateObj
          .getHours()
          .toString()
          .padStart(2, '0')
        let minutes = dateObj
          .getMinutes()
          .toString()
          .padStart(2, '0')
        return `${hours}:${minutes}`
      }
      formData = {
        roomName: roomName,
        guests: selectedGuests,
        date: date,
        startTime: formatTime(startTime),
        endTime: formatTime(date),
        coverId: selectedCover.id,
        description: description
      }
    }
    const apiData = formatDataForAPI(formData, false, false)
    apiData.audio = newSequences
    setIsSaving(true)
    api.events
      .updateById(podcastId, { ...apiData })
      .then(() => {
        setIsSaving(false)
      })
      .catch(error => {
        setIsSaving(false)
        message.error(
          typeof error === String
            ? error
            : error.errorMessage || 'Failed to update  podcast audio!'
        )
      })
  }
  const handleMenuClick = menu => {
    setDiscussionRoomType(menu.key)
  }
  return (
    <Spin spinning={isFetching}>
      <section
        className="section"
        style={{ paddingBottom: '10rem', marginRight: '3.2rem' }}
      >
        <FormHeader
          t={t}
          isRecording={isRecording}
          isMarketPlace={isMarketPlace}
          isEnd={isEnd}
          podcastId={podcastId}
          showAiGenerationOption={true}
          handleMenuClick={handleMenuClick}
          discussionRoomType={discussionRoomType}
        />
        <Row gutter={[16, 16]}>
          <Col
            xs={24}
            sm={24}
            md={podcastId || discussionRoomType === 'custom' ? 16 : 24}
          >
            <Form>
              {isMarketPlace ? (
                <MarketPlaceForm
                  isFetching={isFetching}
                  marketPlaceData={marketPlaceData}
                  setMarketPlaceData={setMarketPlaceData}
                  t={t}
                  user={user}
                />
              ) : (
                <Fragment>
                  <AddGuest
                    t={t}
                    isEnd={isEnd}
                    users={users}
                    selectedGuests={selectedGuests}
                    setSelectedGuests={setSelectedGuests}
                    roomName={roomName}
                    setRoomName={setRoomName}
                    roomNameError={roomNameError}
                    setRoomNameError={setRoomNameError}
                    hostError={hostError}
                    changeHostError={status => {
                      setHostError(status)
                    }}
                  />
                  {discussionRoomType !== 'custom' && (
                    <SelectDate
                      t={t}
                      dateError={dateError}
                      changeDateError={status => {
                        setDateError(status)
                      }}
                      podcastId={podcastId}
                      dateTime={dateTime}
                      selectedDate={selectedDate}
                      setSelectedDate={setSelectedDate}
                      selectedEndTime={selectedEndTime}
                      setSelectedEndTime={setSelectedEndTime}
                      selectedStartTime={selectedStartTime}
                      setSelectedStartTime={setSelectedStartTime}
                    />
                  )}

                  {audioUrl.url && isEnd && isRecording && (
                    <AddAudioLanguage
                      isAiEnabled={isAiEnabled}
                      audioUrl={audioUrl}
                      handleAiLanguage={e => {
                        setAiLanguage(e)
                      }}
                      changeIsAiEnabled={() =>
                        !audioUrl.is_ai_enabled && setIsAiEnabled(!isAiEnabled)
                      }
                    />
                  )}
                  <AttachFile
                    onDropPdf={onDropPdf}
                    pdfFiles={attachedData.pdfFiles}
                    library={props.library}
                    filenames={attachedData.filenames}
                    onHandleDelete={onHandleDelete}
                    checkedCompletUpload={checkedCompletUpload}
                    attachmentIds={attachedData.attachmentIds}
                    attachModal={attachedData.attachModal}
                    setAttachModal={status => {
                      setAttachedData({
                        ...attachedData,
                        attachModal: status
                      })
                    }}
                    modalOpen={modalOpen}
                    toggleMediaModal={() => {
                      setAttachedData({
                        ...attachedData,
                        attachModal: modalOpen
                      })
                      setModal(!modalOpen)
                    }}
                    selectedItem={selectedItem}
                    hidePreviewModal={hidePreviewModal}
                    handleFileInsert={handleFileInsert}
                    handleInsertFile={handleInsertFile}
                    handleRemoveSelected={handleRemoveSelected}
                  />
                  <AddCover
                    t={t}
                    upload={props.upload}
                    coverError={coverError}
                    selectedCover={selectedCover}
                    setSelectedCover={cover => {
                      setCoverError(false)
                      if (podcastId) {
                        handleSubmitCover(cover.id)
                      }
                      setSelectedCover(cover)
                    }}
                  />
                  <Description
                    t={t}
                    description={description}
                    setDescription={setDescription}
                  />
                  {podcastId && (
                    <Setting
                      t={t}
                      isDisabled={
                        isDisabled ||
                        !isAiEnabled ||
                        !podcastAudio.list.length > 0 ||
                        isSaving
                      }
                      selectedType={selectedType}
                      isSuperAdmin={user.isSuperAdmin}
                      isAdmin={user.isAdmin}
                      checkedTerms={checkedTerms}
                      handleChangeTerms={() => {
                        setChangeTerms(!checkedTerms)
                      }}
                      organizationIds={organizationIds}
                      list={props.list}
                      handleSelectedType={handleSelectedType}
                      setOrganizationIds={setOrganizationIds}
                    />
                  )}
                </Fragment>
              )}
            </Form>
          </Col>
          {(discussionRoomType === 'custom' || podcastId) && (
            <Col xs={24} sm={24} md={8}>
              <AudioList
                t={t}
                clearUploadList={props.clearUploadList}
                upload={props.upload}
                library={props.library}
                roomName={roomName}
                selectedCover={selectedCover}
                description={description}
                isSuperAdmin={user.isSuperAdmin}
                isAdmin={user.isAdmin}
                audioUrl={audioUrl}
                isCreator={creatorId === user.id || user.isSuperAdmin}
                podcastId={podcastId}
                isDownLoading={isDownLoading}
                dataList={podcastAudio.list}
                handleSelectAudio={handleSelectAudio}
                changeUploadingStatus={val => setIsUploading(val)}
                setLocalAudio={setLocalAudio}
                setMultipleLocal={setMultipleLocal}
                removeLocalAudio={removeLocalAudio}
                isSaving={isSaving}
                isPublishing={isPublishing}
                isUploading={isUploading}
                onDragDrop={onDragDrop}
              />
            </Col>
          )}
        </Row>
      </section>
      <div className="p-footer">
        <div className="p-footer__inner">
          {!isFetching && (
            <div className="p-footer__item">
              {selectedType === 1 && podcastId && (
                <Checkbox
                  onChange={() => {
                    setSendMail({
                      isSendMail: false,
                      isAdminOnly: !sendMail.isAdminOnly
                    })
                  }}
                  checked={sendMail.isAdminOnly}
                >
                  {general.admin_only}&nbsp;
                </Checkbox>
              )}
              {(selectedType === 2 || selectedType === 3) && podcastId && (
                <Checkbox
                  onChange={() => {
                    setSendMail({
                      ...sendMail,
                      isSendMail: !sendMail.isSendMail
                    })
                  }}
                  disabled={sendMail.isAdminOnly}
                  checked={sendMail.isSendMail}
                >
                  {general.send_email}&nbsp;
                </Checkbox>
              )}
              {podcastId && (
                <Fragment>
                  &nbsp;
                  <Tooltip
                    placement="left"
                    title={
                      isMarketPlace
                        ? t('v4:btn_save_marketplace')
                        : t('v4:btn_edit_marketplace')
                    }
                    arrow
                  >
                    <Button
                      size="large"
                      shape="round"
                      onClick={() => {
                        if (isMarketPlace) {
                          saveMarketPlace()
                        }
                        setMarketPlace(!isMarketPlace)
                      }}
                      className="shl-primary-btn"
                      loading={isSaving}
                      disabled={isPublishing || isUploading.status}
                    >
                      {isMarketPlace ? (
                        <span>{t('v3:save_and_continue')}</span>
                      ) : (
                        <Fragment>
                          {!isSaving ? (
                            <div style={{ display: 'flex' }}>
                              <ReactSVG
                                src={marketplace}
                                className="svg-icon"
                              />
                              &nbsp;
                              {t('buttons:market_place').toUpperCase()}
                            </div>
                          ) : (
                            <span>
                              {t('buttons:market_place').toUpperCase()}
                            </span>
                          )}
                        </Fragment>
                      )}
                    </Button>
                  </Tooltip>
                </Fragment>
              )}
              {isMarketPlace && (
                <Fragment>
                  &nbsp;
                  <Button
                    size="large"
                    shape="round"
                    onClick={() => {
                      setMarketPlace(false)
                    }}
                    className="shl-secondary-btn"
                    loading={isSaving}
                    disabled={isPublishing || isUploading.status}
                  >
                    {t('buttons:cancel')}
                  </Button>
                </Fragment>
              )}
              {!isMarketPlace && discussionRoomType === 'live' && (
                <Fragment>
                  &nbsp;
                  <Tooltip
                    placement="left"
                    title={
                      isEdit && podcastId && isEnd
                        ? t('v4:createNewTitle')
                        : !isEdit && podcastId
                        ? t('v4:updateTitle')
                        : !isEdit && !podcastId
                        ? t('v4:createTitle')
                        : ''
                    }
                    arrow
                  >
                    <span>
                      <Button
                        type="primary"
                        className="rounded"
                        loading={isSaving}
                        disabled={
                          isMarketPlace || isPublishing || isUploading.status
                        }
                        onClick={handleSubmit}
                      >
                        {!isEdit && podcastId && t('v4:updateButton')}
                        {!isEdit && !podcastId && t('v4:createButton')}
                        {isEdit &&
                          podcastId &&
                          isEnd &&
                          t('v4:createNewButton')}
                      </Button>
                    </span>
                  </Tooltip>
                </Fragment>
              )}
              {!isMarketPlace && discussionRoomType === 'custom' && (
                <Fragment>
                  &nbsp;
                  <Tooltip placement="left" title={t('v4:customTitle')} arrow>
                    <span>
                      <Button
                        type="primary"
                        className="rounded"
                        loading={isSaving}
                        disabled={isPublishing || isUploading.status}
                        onClick={() => {
                          submitCustomPodcast(podcastId ? true : false)
                        }}
                      >
                        {podcastId
                          ? t('v4:updateButton')
                          : t('v4:createButton')}
                      </Button>
                    </span>
                  </Tooltip>
                </Fragment>
              )}
              {podcastId && !isMarketPlace && (
                <Fragment>
                  &nbsp;
                  <Tooltip
                    placement="topLeft"
                    title={t('v4:publishTitle')}
                    arrow
                  >
                    <span>
                      <Button
                        disabled={
                          !selectedType ||
                          !checkedTerms ||
                          !isAiEnabled ||
                          isUploading.status ||
                          creatorId !== user.id ||
                          !podcastAudio.list.length > 0 ||
                          isSaving ||
                          !isEnd
                        }
                        type="primary"
                        className="rounded"
                        loading={isPublishing}
                        onClick={handlePublish}
                      >
                        {t('buttons:publish')}
                        <Icon type="right" />
                      </Button>
                    </span>
                  </Tooltip>
                </Fragment>
              )}
            </div>
          )}
        </div>
      </div>
    </Spin>
  )
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      upload: actions.library.upload,
      clearUploadList: actions.library.clearUploadList,
      updateUser: actions.user.updateUser,
      updateTopicList: actions.topics.updateTopicList,
      get: actions.organizations.get
    },
    dispatch
  )

export default connect(null, mapDispatchToProps)(DiscussionCreateMain)
